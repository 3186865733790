// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css';
import axios from '@/axios.js'; 
import './assets/style.css';
import './assets/fonts/NeueMachina-Light.otf'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueMobileDetection from "vue-mobile-detection";// Importez axios explicitement
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { createHead } from '@vueuse/head'
// Vuesax
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import apiPlugin from './apiPlugin';
import VueSocialSharing from 'vue-social-sharing'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import vue3GoogleLogin from 'vue3-google-login'

// Import des styles globaux (si nécessaire)
import './registerServiceWorker'; // Assurez-vous que ce fichier contient ce qui est nécessaire pour le service worker

// Création de l'application Vue
const app = createApp(App);
const head = createHead();

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
// Utilisation des plugins (router et store)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAAPq0rbF00rHVB3rt-V_VMy-e1l4AHTg4',
    libraries: 'places', // Optionnel : pour utiliser des fonctionnalités supplémentaires
  },
});
app.use(VueSocialSharing);
app.use(vue3GoogleLogin, {
  clientId: '436764491754-4f2jbsd527ub8bkbmbjv6dd5jlnqr0sb.apps.googleusercontent.com'
});
app.use(head);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(apiPlugin);
app.use(VueMobileDetection);
app.use('DatePicker', DatePicker);

// Ajouter axios aux propriétés globales
app.config.globalProperties.$axios = axios;

// Montage de l'application dans l'élément avec l'ID 'app'
app.mount('#app');
