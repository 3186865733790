<template>
  <div>
    <!-- Fixebar avec NavbarWeb et FilterWeb -->
    <div class="fixebar">
      <NavbarMobile msg="Welcome to Your Vue.js App"/>
    </div>
    
    <div class="list-container">
      <div class="accueil-bodyimg-themea_mobile rounded-2" style="margin: 12px; border-radius: 10px">
        <div style="padding: 10px;">
            <center class="mt-5" style="margin-top: 3px !important;">
              <h5 class="accueil-bodysubtitle-themea">Plateforme de réservation de résidences meublées et d'hôtel en Côte d'Ivoire.</h5>
              <Searchbar/> 
            </center>
        </div>
      </div>

      <div class="results-toolbar" v-if="searchOk">
        <span class="results-count">Résultats : <b>{{ biens.total_row }}</b> - page {{ biens.rpage }}-{{ biens.total_pages }}</span>
        <div class="toolbar-actions">
          <div class="view-buttons">
            <button class="button" @click="gridChange(false)">
              <span class="icon is-small">
                <i class="fa-solid fa-bars"></i>
              </span>
            </button>
            <button class="button" @click="gridChange(true)">
              <span class="icon is-small">
                <i class="fa-solid fa-square"></i>
              </span>
            </button>
            <button style="background-color: #d19100; color: white" class="button filter-button" @click="openFilters">
              <span class="icon is-small">
                <i class="fa-solid fa-filter"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Vue Liste ou Grille -->
      <div v-if="!showMap">
        <div v-if="listGrid">
          <ListeMobileList :msg="biens.datas"/>
        </div>
        <div v-else>
          <ListeMobileGrid :msg="biens.datas"/>
        </div>
        <div class="load-more-container" v-if="biens.rpage < biens.total_pages">
          <button 
            class="load-more-button" 
            @click="loadMore"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              <i class="fa-solid fa-spinner fa-spin"></i> Chargement...
            </span>
            <span v-else>
              <i class="fa-solid fa-arrow-down mr-2"></i> Charger plus
            </span>
          </button>
        </div>
        <Footer />
        <div class="map-button-container" v-if="!showMap && showMapButton">
          <button class="map-view-button" @click="toggleMapView">
            <span class="icon is-small mr-2">
              <i class="fa-solid fa-map"></i>
            </span>
            Voir carte
          </button>
        </div>
      </div>

      <!-- Vue Carte -->
      <div v-if="showMap" class="map-container">
        <button class="close-map-button" @click="toggleMapView">
          <span class="icon is-small">
            <i class="fa-solid fa-arrow-left"></i> <!-- Icône de retour -->
          </span>
          Mode Liste
        </button>
        <GMapMap
          :center="{ lat: 5.318790, lng: -4.013572 }"
          :zoom="12"
          map-type-id="terrain"
          style="width: 100%; height: calc(100vh - 60px)"
          ref="gmap"
          @bounds_changed="onMapReady"
        >
          <template v-if="mapReady">
            <CustomOverlay
              v-for="(residence, index) in biens.datas"
              :key="index"
              :position="residence.position"
              :price="residence.residence.prix_nuit"
              :map="$refs.gmap.$mapObject"
              @click="showDetails(residence)"
            />
          </template>
        </GMapMap>
      </div>
    </div>

    <!-- Modal Filtres -->
    <div v-if="isFilterModalOpen" class="modal-overlay" @click="closeFilters">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeFilters">&times;</button>
        <FilterComponent />
      </div>
    </div>

     <!-- Afficher les détails de la résidence -->
     <ResidenceDetails
      v-if="selectedResidence"
      :residence="selectedResidence"
      @close="closeResidenceDetails"
    />

    <BottomBar/>
  </div>
</template>

<script>
import CustomOverlay from './pointer.vue';
import ResidenceDetails from './ResidenceDetails.vue';
import { askOpenAI } from '../../../openaiService';
import { ElLoading, ElNotification } from 'element-plus'
import NavbarMobile from '@/components/accueil/accueil_mobile/Navbar.vue'
import ListeMobileGrid from '@/components/accueil/accueil_mobile/Liste.vue'
import ListeMobileList from '@/components/accueil/accueil_mobile/Liste_list.vue'
import BottomBar from '@/components/accueil/accueil_mobile/Bottom_bar.vue';
import Searchbar from '@/components/accueil/accueil_mobile/SearchBar.vue';
import FilterComponent from '@/components/accueil/accueil_mobile/Filter.vue';
import Footer from '@/views/Footer.vue'

export default {
  name: 'AccueilIndex',
  components: {
    NavbarMobile,
    ListeMobileList,
    ListeMobileGrid,
    BottomBar,
    Searchbar,
    FilterComponent,
    CustomOverlay,
    ResidenceDetails,
    Footer
  },
  data() {
    return {
      isLoading: false,
      showMap: false, // Contrôle l'affichage de la carte
      showMapButton: true, // Contrôle l'affichage du bouton de carte
      searchOk: false,
      isFilterModalOpen: false,
      total_row: 0,
      total_pages: 0,
      page: 1,
      datas: [],
      value1: [1, 80],
      select1: '0',
      select2: '0',
      selectedType: '0',
      filters: {
        piscine: '',
        restaurant: '',
        bordureMer: '',
        evenement: '',
        internet: '',
      },
      residences: [
        {
          name: 'Résidence 1',
          position: { lat: 5.340772, lng: -4.005470 },
          price: '100 000 F',
          image: 'https://via.placeholder.com/150',
          description: 'Une belle résidence avec vue sur la mer.',
          address: 'Adresse 1, Abidjan',
        },
        {
          name: 'Résidence 2',
          position: { lat: 5.309792, lng: -4.005670 },
          price: '150 000 F',
          image: 'https://via.placeholder.com/150',
          description: 'Résidence moderne avec piscine.',
          address: 'Adresse 2, Abidjan',
        },
      ],
      response: null,
      mapReady: false,
      selectedResidence: null,
    };
  },
  computed: {
    listGrid() {
      return this.$store.state.listGrid;
    },
    biens() {
      return this.$store.state.biens;
    },
  },
  methods: {
    toggleMapView() {
      this.showMap = !this.showMap; // Bascule entre la vue liste et la vue carte
    },
    onMapReady() {
      this.mapReady = true;
    },
    showDetails(residence) {
      this.selectedResidence = residence;
    },
    closeResidenceDetails() {
      this.selectedResidence = null; // Masquer les détails de la résidence
    },
    async getResponse() {
      if (!this.userInput) {
        alert("Veuillez saisir une question.");
        return;
      }
      try {
        this.response = await askOpenAI(this.userInput);
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    openWhatsApp() {
      const debut = this.formattedDate;
      const phoneNumber = '2250153676062';
      const uniqueParam = `v=${Date.now()}`;
      const reservationDetails = `Bonjour, j'aimerais réserver pour 2 jours, du ${debut}. La résidence: ${window.location.href}?${uniqueParam}`;
      const message = encodeURIComponent(reservationDetails);
      const url = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(url, '_blank');
    },
    openFilters() {
      this.isFilterModalOpen = true;
    },
    closeFilters() {
      this.isFilterModalOpen = false;
    },
    handlePageChange(page) {
      this.$store.commit('setBiensPage', page);
      this.biens.rpage = page;
      this.ListeFiltre2();
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    checkScrollPosition() {
      // Calcul de la position de défilement
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      // Vérifier si on est à 150px ou moins de la fin de la page
      const isNearBottom = documentHeight - (scrollPosition + windowHeight) <= 150;
      
      // Vérifier si on est à 500px ou moins du haut de la page
      const isNearTop = scrollPosition <= 500;
      
      // Masquer le bouton si on est près du haut OU près du bas
      if (isNearBottom || isNearTop) {
        this.showMapButton = false;
      } else {
        this.showMapButton = true;
      }
    },
    async activerCompte() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      
      if (code) {
        const chargement = ElLoading.service({
          lock: true,
          text: 'Activation du compte...',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        try {
          const reponse = await this.$api.postActivation({ token: code });

          if (reponse.data.valide === 1) {
            ElNotification({
              title: 'Succès',
              message: reponse.data.mes || 'Compte activé avec succès',
              type: 'success',
            });
          } else {
            ElNotification({
              title: 'Erreur',
              message: reponse.data.message || 'Erreur lors de l\'activation du compte',
              type: 'error',
            });
            this.$router.replace('/')
          }
        } catch (erreur) {
          console.error('Erreur de requête:', erreur);
          ElNotification({
            title: 'Erreur',
            message: 'Une erreur est survenue lors de l\'activation du compte',
            type: 'error',
          });
        } finally {
          chargement.close();
        }
      }
    },
    ListeFiltre2() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading...',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      // Reset to page 1 when filtering
      this.biens.rpage = 1;
      this.$store.commit('setBiensPage', 1);

      const postData = {
        prix_min: this.biens.prix_min,
        prix_max: this.biens.prix_max,
        type: this.biens.rtype,
        ville: this.biens.rville,
        parpage: 10,
        commune: this.biens.rquartier,
      };

      if(postData.prix_max == 46000) {
        postData.prix_max = 900000;
      }

      const booleanProps = [
        'piscine', 'internet', 'balcon', 'jaccuzi', 'parking',
        'bord_mer', 'bord_route', 'climatisation', 'netflix',
        'moustiquaire', 'refrigerateur', 'lave_machine'
      ];

      booleanProps.forEach(prop => {
        postData[prop] = this.biens[prop] ? 1 : 0;
      });

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') { 
        postData.type = '0';
      }

      const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page='+ this.biens.rpage+'&id='+ randomId, postData)
        .then(response => {
          this.searchOk = true;
          this.datas = response.data.data;
          let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
            rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage
          }
          this.$store.commit('setDatas', data);
          let dons = response.data.data[0];
          this.$store.commit("setDonneesBien", dons);
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        })
        .finally(() => {
          loading.close();
        });
    },
    gridChange(etat) {
      const grid = !etat;
      this.$store.commit('setListGrid', grid);
    },
    loadMore() {
      this.isLoading = true;
      const nextPage = this.biens.rpage + 1;
      
      if (nextPage <= this.biens.total_pages) {
        this.$store.commit('setBiensPage', nextPage);
        this.biens.rpage = nextPage;
        
        const loading = ElLoading.service({
          lock: true,
          text: 'Chargement...',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        const postData = {
          prix_min: this.biens.prix_min,
          prix_max: this.biens.prix_max,
          type: this.biens.rtype,
          ville: this.biens.rville,
          parpage: 10,
          commune: this.biens.rquartier,
        };

        const booleanProps = [
          'piscine', 'internet', 'balcon', 'jaccuzi', 'parking',
          'bord_mer', 'bord_route', 'climatisation', 'netflix',
          'moustiquaire', 'refrigerateur', 'lave_machine'
        ];

        booleanProps.forEach(prop => {
          postData[prop] = this.biens[prop] ? 1 : 0;
        });

        if (postData.prix === 'Tarif') {
          postData.prix = '0';
        }

        if (postData.type === 'Types') { 
          postData.type = '0';
        }

        const randomId = Math.floor(Math.random() * 1000000);
        this.$axios.post('/web_liste_appart.php?page='+ this.biens.rpage+'&id='+ randomId, postData)
          .then(response => {
            this.searchOk = true;
            // Append new data instead of replacing
            const newData = {
              datas: [...this.biens.datas, ...response.data.data],
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage
            };
            this.$store.commit('setDatas', newData);
          })
          .catch(error => {
            console.error('Erreur de requête:', error);
          })
          .finally(() => {
            loading.close();
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.ListeFiltre2();
    this.activerCompte();
    
    // Ajouter l'écouteur d'événement de défilement
    window.addEventListener('scroll', this.checkScrollPosition);
    
    // Vérifier la position initiale
    this.checkScrollPosition();
  },
  beforeUnmount() {
    // Supprimer l'écouteur d'événement pour éviter les fuites de mémoire
    window.removeEventListener('scroll', this.checkScrollPosition);
  }
};
</script>

<style scoped>
.fixebar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.list-container {
  margin-top: 70px;
  position: relative;
  z-index: 900;
}

.results-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.results-count {
  font-size: 1rem;
}

.view-buttons {
  display: flex;
}

.button {
  margin-left: 5px;
}

.map-container {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 800;
}

.close-map-button {
  position: fixed;
  top: 70px; /* Ajustez en fonction de la hauteur de votre navbar */
  right: 10px;
  z-index: 1000;
  background-color: white;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.close-map-button:hover {
  background-color: #f5f5f5;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 70px 0;
}

.load-more-button {
  background-color: #d19100;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.load-more-button:hover {
  background-color: #b37e00;
}

.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.map-button-container {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.3s ease;
}

.map-view-button {
  background-color: #000000b3;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-view-button:hover {
  background-color: #b37e00;
}

.map-view-button .icon {
  margin-right: 5px;
}
</style>

