import axios from 'axios';

const apiKey = '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';
const baseURL = 'https://api.openai.com/v1';

const openaiService = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${apiKey}`,
  },
});

export const askOpenAI = async (prompt, model = 'gpt-3.5-turbo') => {
  try {
    const response = await openaiService.post('/chat/completions', {
      model: model,
      messages: [{ role: 'user', content: prompt }],
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Erreur lors de l’appel à OpenAI :', error.response || error);
    throw error;
  }
};
