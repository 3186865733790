<template>
  <div class="residence-details" v-if="residence">
    <div class="details-container" @click="changeBiens(residence)">
      <!-- Bouton de fermeture -->
      <el-button type="text" class="close-button" @click.stop="closeDetails">
        <i class="fa-solid fa-xmark icon"></i> <!-- Icône de fermeture -->
      </el-button>
      <!-- Conteneur flex pour l'image et les détails -->
      <div class="details-flex">
        <!-- Image (2/5 de la largeur) -->
        <div class="image-container">
          <img :src="residence.images[0].lien" alt="Residence Image" class="details-image" />
        </div>
        <!-- Détails (3/5 de la largeur) -->
        <div class="details-content">
          <h3 style="font-weight: bold;">{{ residence.residence.libelle_resid }}</h3>
          <p>{{ residence.residence.adresse_resid }}</p>
          <p><strong>Prix :</strong> {{ residence.residence.prix_nuit }}</p>
          <p><strong>Adresse :</strong> {{ residence.residence.ville_resid }} {{ residence.residence.commune_resid }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    residence: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeDetails() {
      this.$emit('close');
    },
    changeBiens(bien) {
      console.log(bien.residence.id_resid);
      this.$router.push({ name: 'detail', params: { param: bien.residence.id_resid } });
    // Déclencher la mutation pour stocker les détails du bien
    //this.$store.commit("setDonneesBien", bien);
    // Naviguer vers la page de détails en passant l'identifiant du bien en paramètre
    },
  },
};
</script>

<style scoped>
.residence-details {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1001;
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%; /* Ajustez selon vos besoins */
}

.details-container {
  position: relative;
  cursor: pointer;
}

.details-flex {
  display: flex;
  gap: 10px; /* Espace entre l'image et les détails */
}

.image-container {
  flex: 2; /* 2/5 de la largeur */
}

.details-image {
  width: 100%;
  height: auto;
  max-height: 120px; /* Ajustez selon vos besoins */
  object-fit: cover;
  border-radius: 8px;
}

.details-content {
  flex: 3; /* 3/5 de la largeur */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h3 {
  margin: 0 0 8px 0;
  font-size: 16px; /* Ajustez selon vos besoins */
}

p {
  margin: 4px 0;
  font-size: 13px; /* Ajustez selon vos besoins */
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: white;
  background: #d19100; /* Arrière-plan légèrement transparent */
  border: 1px solid #ddd; /* Bordure pour plus de visibilité */
  border-radius: 50%;
  padding: 5px;
  z-index: 1002; /* Assurez-vous qu'il est au-dessus de tout */
  cursor: pointer;
  width: 30px; /* Taille fixe */
  height: 30px; /* Taille fixe */
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: #333;
  background: rgba(255, 255, 255, 1);
  border-color: #ccc;
}
</style>