import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios'

export default createStore({
  state: {
    loginActive: false,
    LoginPage: false,
    biens: {
      datas: [],
      total_row: 0,
      total_pages: 0,
      rtype: '0',
      prix_min: 5000,
      prix_max: 900000,
      rville: '0',
      rquartier: '0',
      rpage: 1,
      piscine: 0,
      internet: 0,
      balcon: 0,
      jaccuzi: 0,
      parking: 0,
      bord_mer: 0,
      bord_route: 0,
      climatisation: 0,
      netflix: 0,
      moustiquaire: 0,
      refrigerateur: 0,
      lave_machine: 0,
    },
    modeNew: true,
    donneesBiens: [],
    listGrid: false,
    langueDevise: {
      langue: 'fr',
      devise: 'XOF'
    },
    rechercheInfos: {
      destination: 'Abidjan',
      du: '',
      au: '',
      adulte: 2,
      enfant: 0,
      date: []
    },
    uniquser: '',
    users: {
      nom: '',
      prenom: '',
      email: '',
      contact: '',
      photo: '',
      statut: '',
      password: '',
      id_user: null,
      agent: 'KO',
      ville: ''
    },
    formulaire: {
      hote: {
        nom_hote: null,
        contact1_hote: 0,
        contact2_hote: 0,
        email: '',
        texte_visiteur: '',
        photo: 'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1'
      },
      images: {
        image1: 'http://res.cloudinary.com/r-sidence-meubl-e/image/upload/v1719916169/202462102999027.jpg',
        image2: 'a',
        image3: 'a',
        image4: 'a',
        image5: 'a',
        image6: 'a',
        image7: 'a',
        image8: 'a',
        image_id1: '1',
        image_id2: '1',
        image_id3: '1',
        image_id4: '1',
        image_id5: '1',
        image_id6: '1',
        image_id7: '1',
        image_id8: '1',
      },
      adresse: {
        ville: 'Abidjan',
        commune: 'Cocody',
        adresse: ''
      },
      residence: {
        libelle: '',
        prix_nuit: '',
        type: '',
        reduction: 'Non',
        prix_reduction: '',
        duree_reduction: '',
        serch_zone: '',
        description: '',
        user: 1
      },
      services: {
        espace: false,
        restaurant: false,
        bar: false,
        petit_dej: false
      },
      service_icon: [],
      espace: [],
      restaurant: [],
      bar: [],
      petit_dej: [],
    },
    formulaireUpdate: {
      residence: {
        id_resid: '',
        libelle_resid: '',
        ville_resid: '',
        commune_resid: '',
        adresse_resid: '',
        prix_nuit: '',
        type_resid: '',
        reduction_resid: 'Non',
        prix_reduction: '',
        duree_reduction: '',
        date_enregistrement: '',
        statut_resid: '',
        etat: '',
        searchzone: '',
        user: '',
        description: ''
      },
    },

    
    alert: {
      hote: {
        nom: false,
        contact1_hote: false,
        contact2_hote: false
      },
      residence: {
        libelle: false,
        prix_nuit: false,
        type: false
      },
      adresse: {
        ville: false,
        commune: false,
      },
      images: {
        image5: false,
        limite: false
      }
    }
  },
  getters: {
    // Exemple de getter pour accéder aux donnéesBiens
    getDonneesBiens: state => state.donneesBiens,
    getalert: state => state.alert,
  },
  mutations: {

    setRechercheInfos(state, data) {
      state.rechercheInfos.destination = data.destination;
      state.rechercheInfos.du = data.du;
      state.rechercheInfos.au = data.au;
      state.rechercheInfos.adulte = data.adulte;
      state.rechercheInfos.enfant = data.enfant;
    },

    setRechercheInfos2(state, data) {
      state.rechercheInfos.destination = data.destination;
      state.rechercheInfos.date = data.date;
      state.rechercheInfos.adulte = data.adulte;
      state.rechercheInfos.enfant = data.enfant;
    },

    setDatas(state, data) {
      state.biens.datas = data.datas;
      state.biens.total_row = data.total_row;
      state.biens.total_pages = data.total_pages;
      state.biens.rtype = data.rtype;
      state.biens.rville = data.rville;
      state.biens.rquartier = data.rquartier;
      state.biens.rpage = data.rpage;
      
    },

    setData(state, data) {
      state.biens.datas = data.datas;
      state.biens.total_row = data.total_row;
      state.biens.total_pages = data.total_pages;
      
    },

    setFilter(state, data) {
      state.biens.piscine = data.piscine;
      state.biens.internet = data.internet;
      state.biens.balcon = data.balcon;
      state.biens.jaccuzi = data.jaccuzi;
      state.biens.parking = data.parking;
      state.biens.bord_mer = data.bord_mer;
      state.biens.bord_route = data.bord_route;
      state.biens.climatisation = data.climatisation;
      state.biens.netflix = data.netflix;
      state.biens.moustiquaire = data.moustiquaire;
      state.biens.refrigerateur = data.refrigerateur;
      state.biens.lave_machine = data.lave_machine;
      
    },

    setBiensPage(state, data) {
      state.biens.rpage = data;
    },

    setMode(state, data) {
      state.modeNew = data;
    },

    setBiensSearch(state, data) {
      state.biens.rville = data.ville;
      state.biens.rquartier = data.commune;
      state.biens.rtype = data.type;
    },

    setBiensPrix(state, data) {
      state.biens.prix_min = data.prix_min;
      state.biens.prix_max = data.prix_max;
    },

    setLanguage(state, langue) {
      state.langueDevise.langue = langue;
    },

    setLogin(state, data) {
      state.loginActive = data;
    },

    setLoginPage(state, data) {
      state.LoginPage = data;
    },

    Preferences: (state, data) => {
      state.langueDevise.langue = data.langue;
      state.langueDevise.devise = data.devise;
    },

    ConnexionAgent: (state, data) => {
      state.users.nom = data.nom;
      state.users.prenom = data.prenom;
      state.users.email = data.email;
      state.users.contact = data.contact;
      state.users.photo = data.photo;
      state.users.statut = data.statut;
      state.users.id_user = data.id_user;
      state.users.agent = data.agent;
    },

    AgentPhoto: (state, data) => {
      state.users.photo = data;
    },

    StatutAgent: (state, data) => {
      state.users.agent = data;
    },
    // Mutations pour mettre à jour donneesBiens
    setDonneesBien(state, bien) {
      state.donneesBiens = bien;
    },

    setDonneesBienn(state, bien) {
      state.formulaire.hote = {
        nom_hote: bien.hote.nom_hote,
        contact1_hote: bien.hote.contact1_hote,
        contact2_hote: bien.hote.contact2_hote,
        email: bien.hote.email_hote,
        texte_visiteur: bien.hote.texte_visiteur,
        photo: bien.photo_hote
      };
      state.formulaire.residence = {
        libelle: bien.residence.libelle_resid,
        prix_nuit: bien.residence.prix_nuit,
        type: bien.residence.type_resid,
        reduction: bien.residence.reduction_resid,
        prix_reduction: bien.residence.prix_reduction,
        duree_reduction: bien.residence.duree_reduction,
        serch_zone: bien.residence.searchzone,
        description: bien.residence.description,
        user: bien.residence.user,
      };

      state.formulaire.service_icon = bien.service_icon;

      state.formulaire.adresse = {
        ville: bien.residence.ville_resid,
        commune: bien.residence.commune_resid,
        adresse: bien.residence.adresse_resid,
      };
      const imagesValides = bien.images.filter(image => image.lien.length > 25);

      // Initialiser le formulaire d'images avec des valeurs par défaut
      state.formulaire.images = {
        image1: 'a',
        image2: 'a',
        image3: 'a',
        image4: 'a',
        image5: 'a',
        image6: 'a',
        image7: 'a',
        image8: 'a',
      };

      // Remplir les images dans state.formulaire.images avec les liens filtrés
      imagesValides.forEach((image, index) => {
        if (index < 8) { // Assurez-vous de ne pas dépasser 8 images
          state.formulaire.images[`image${index + 1}`] = image.lien;
        }
      });
      

    },

    setalert(state, alert) {
      state.alert = alert;
    },
    setListGrid(state, etat) {
      state.listGrid = etat;
    },
    resetFormulaire(state) {
      state.formulaire = {
        hote: {
          nom_hote: null,
          contact1_hote: 0,
          contact2_hote: 0,
          email: "",
          texte_visiteur: "",
          photo: "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1",
        },
        images: {
          image1: "http://res.cloudinary.com/diwfzmygz/image/upload/v1739039031/202518102314845.png",
          image2: "a",
          image3: "a",
          image4: "a",
          image5: "a",
          image6: "a",
          image7: "a",
          image8: "a",
          image_id1: "1",
          image_id2: "1",
          image_id3: "1",
          image_id4: "1",
          image_id5: "1",
          image_id6: "1",
          image_id7: "1",
          image_id8: "1",
        },
        adresse: {
          ville: "Abidjan",
          commune: "Cocody",
          adresse: "",
        },
        residence: {
          libelle: "",
          prix_nuit: "",
          type: "",
          reduction: "Non",
          prix_reduction: "",
          duree_reduction: "",
          serch_zone: "",
          description: "",
          user: 1,
        },
        services: {
          espace: false,
          restaurant: false,
          bar: false,
          petit_dej: false,
        },
        service_icon: [],
        espace: [],
        restaurant: [],
        bar: [],
        petit_dej: [],
      }
    },
  },
  actions: {

    async listeFiltre2({ commit }, postData) {
      try {
        const response = await axios.post('/web_liste_appart.php?page=' + this.state.biens.rpage + '&id=' + Math.floor(Math.random() * 1000000), postData);
        const data = {
          datas: response.data.data,
          total_row: response.data.total_rows,
          total_pages: response.data.total_pages,
          rtype: this.state.biens.rtype,
          rville: this.state.biens.rville,
          rquartier: this.state.biens.rquartier,
          rpage: this.state.biens.rpage,
        };
        commit('setDatas', data);
        commit('setDonneesBien', response.data.data[0]);
        return data;
      } catch (error) {
        console.error('Erreur de requête:', error);
        throw error;
      }
    },
    // Exemple d'action pour mettre à jour donneesBiens
    updateDonneesBien({ commit }, bien) {
      commit('setDonneesBien', bien);
    },
  },
  plugins: [createPersistedState()], // Utilisation de vuex-persistedstate
  modules: {
    // Modules Vuex si nécessaire
  },
});
