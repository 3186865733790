import { getResidenceUser, deleteResidence, getReservationsUser, getReservationsProp, getReservationsAdmin, 
  getResidenceFavoris, postReservation, updateReservation, postFavoris, deleteFavoris, postLogin, postInscription, 
  postAgent, postQuartier, getQuartier, getQuartier2, getAvis, postAvis, putProfile, updateImage, updateImage2, 
  postInscriptionReseau, postOccupationResidence, checkResidenceAvailability, getOccupations, postActivation,
  cancelReservation, valideReservation, postPaiement} from './api';

export default {
  install(app) {
    app.config.globalProperties.$api = {
      getResidenceUser,
      deleteResidence,
      getReservationsUser,
      getReservationsProp,
      getReservationsAdmin,
      getResidenceFavoris,
      postReservation,
      updateReservation,
      postFavoris,
      deleteFavoris,
      postLogin,
      postInscription,
      postInscriptionReseau,
      putProfile,
      postAgent,
      postQuartier,
      getQuartier,
      getQuartier2,
      getAvis,
      postAvis,
      updateImage,
      updateImage2,
      postOccupationResidence,
      checkResidenceAvailability,
      getOccupations,
      postActivation,
      cancelReservation,
      valideReservation,
      postPaiement
      // Ajoutez d'autres méthodes API ici
    };
  },
};