```vue type="vue" project="SearchBar Redesign" file="components/accueil/accueil_mobile/SearchBar.vue"
[v0-no-op-code-block-prefix]<template>
  <div class="search-container">
    <!-- Barre de recherche principale avec bouton de recherche -->
    <div class="search-bar-wrapper">
      <div class="search-bar" @click="openActionSheet()">
        <div class="search-input-area">
          <div class="search-icon">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="search-placeholder">
            <span class="search-text">{{ getSearchPlaceholder() }}</span>
          </div>
          <div class="search-filters-badge" v-if="hasActiveFilters()">
            <span>{{ getActiveFiltersCount() }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Utilisation de Teleport pour Vue 3 -->
    <teleport to="body">
      <transition name="fade">
        <div v-if="isOpen" class="search-dialog-overlay">
          <div class="search-fullpage">
            <!-- En-tête du panneau -->
            <div class="fullpage-header">
              <h2>Recherche</h2>
              <button class="close-button" @click="closeActionSheet()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>

            <!-- Corps du formulaire -->
            <div class="fullpage-body">
              <div class="search-form">
                <!-- Tabs pour la navigation -->
                <div class="search-tabs">
                  <button 
                    class="tab-button" 
                    :class="{ active: activeTab === 'search' }" 
                    @click="activeTab = 'search'"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                    Recherche
                  </button>
                  <button 
                    class="tab-button" 
                    :class="{ active: activeTab === 'filters' }" 
                    @click="activeTab = 'filters'"
                  >
                    <i class="fa-solid fa-sliders"></i>
                    Filtres
                  </button>
                </div>

                <!-- Contenu de l'onglet Recherche -->
                <div v-if="activeTab === 'search'" class="tab-content">
                  <!-- Destination -->
                  <div class="form-group">
                    <label>
                      <i class="fa-solid fa-location-dot"></i>
                      Destination
                    </label>
                    <div class="select-wrapper">
                      <select v-model="biens.rville" class="form-control" @change="changeVille()">
                        <option value="0">Toutes les villes</option>
                        <option v-for="(ville, index) in uniqueCities" :key="index" :value="ville">
                          {{ ville }}
                        </option>
                      </select>
                      <i class="fa-solid fa-chevron-down select-arrow"></i>
                    </div>
                  </div>

                  <!-- Quartier -->
                  <div class="form-group" v-if="biens.rville !== '0'">
                    <label>
                      <i class="fa-solid fa-map-pin"></i>
                      Quartier
                    </label>
                    <div class="select-wrapper">
                      <select v-model="biens.rquartier" class="form-control">
                        <option value="0">Tous les quartiers</option>
                        <option v-for="(item, index) in filteredQuartiers" :key="index" :value="item.quartier">
                          {{ item.quartier }}
                        </option>
                      </select>
                      <i class="fa-solid fa-chevron-down select-arrow"></i>
                    </div>
                  </div>

				  <div class="form-group">
						<label>
							<i class="fa-solid fa-money-bill-wave"></i>
							Prix
						</label>
						<div class="price-range-container">
							<div class="price-display">
							<span>{{ formatMoney(value1[0] * 5000) }}</span>
							<span>{{ formatMoney(value1[1] * 5000) }}</span>
							</div>
							<input 
							type="range" 
							class="price-slider" 
							v-model="value1[1]" 
							min="1" 
							max="100" 
							@input="updatePriceRange"
							/>
						</div>
					</div>

                  <!-- Type de logement -->
                  <div class="form-group">
                    <label>
                      <i class="fa-solid fa-house"></i>
                      Type de logement
                    </label>
                    <div class="select-wrapper">
                      <select v-model="biens.rtype" class="form-control">
                        <option value="0">Tous types</option>
                        <option v-for="item in listZone" :key="item.libelle" :value="item.libelle">
                          {{ item.libelle }}
                        </option>
                      </select>
                      <i class="fa-solid fa-chevron-down select-arrow"></i>
                    </div>
                  </div>

                  <!-- Dates -->
                  <div class="form-group dates-group">
                    <label>
                      <i class="fa-solid fa-calendar-days"></i>
                      Dates de séjour
                    </label>
                    <div class="date-inputs">
                      <div class="date-input-wrapper">
                        <input
                          type="date"
                          v-model="rechercheInfos.du"
                          class="form-control date-input"
                          :min="getTodayDate()"
                        />
                        <span class="date-label">Arrivée</span>
                      </div>
                      <div class="date-separator">
                        <i class="fa-solid fa-arrow-right"></i>
                      </div>
                      <div class="date-input-wrapper">
                        <input
                          type="date"
                          v-model="rechercheInfos.au"
                          class="form-control date-input"
                          :min="rechercheInfos.du"
                        />
                        <span class="date-label">Départ</span>
                      </div>
                    </div>
                  </div>

                  <!-- Voyageurs -->
                  <div class="form-group guests-group">
                    <label>
                      <i class="fa-solid fa-user-group"></i>
                      Voyageurs
                    </label>
                    <div class="guests-inputs">
                      <div class="guest-counter">
                        <span class="guest-label">Adultes</span>
                        <div class="counter-controls">
                          <button 
                            class="counter-btn" 
                            @click="decrementAdults()"
                            :disabled="rechercheInfos.adulte <= 1"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <span class="counter-value">{{ rechercheInfos.adulte }}</span>
                          <button 
                            class="counter-btn" 
                            @click="incrementAdults()"
                            :disabled="rechercheInfos.adulte >= 10"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="guest-counter">
                        <span class="guest-label">Enfants</span>
                        <div class="counter-controls">
                          <button 
                            class="counter-btn" 
                            @click="decrementChildren()"
                            :disabled="rechercheInfos.enfant <= 0"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <span class="counter-value">{{ rechercheInfos.enfant }}</span>
                          <button 
                            class="counter-btn" 
                            @click="incrementChildren()"
                            :disabled="rechercheInfos.enfant >= 10"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Contenu de l'onglet Filtres -->
				<div v-if="activeTab === 'filters'" class="tab-content">
					<!-- Price Range Slider (New) -->

					<!-- Filtres d'équipements -->
					<div class="form-group">
					<label>
						<i class="fa-solid fa-list-check"></i>
						Équipements et services
					</label>
					<div class="amenities-grid">
						<button 
						v-for="item in filtre_list" 
						:key="item.libelle"
						class="amenity-button"
						:class="{ active: item.active }"
						@click="toggleActive(item)"
						>
						<i :class="item.icon" class="amenity-icon"></i>
						<span class="amenity-text">{{ item.libelle }}</span>
						</button>
					</div>
					</div>
				</div>

                <!-- Bouton de recherche -->
                <div class="form-group search-button-group">
                  <button class="search-button" @click="ListeFiltre2()">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    Rechercher
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { ElLoading } from 'element-plus'

export default {
  name: 'SearchBar',
  computed: {
    rechercheInfos() {
      return this.$store.state.rechercheInfos;
    },
    uniqueCities() {
      const unique = new Set();
      return this.datasQuartier
        .map(item => item.ville)
        .filter(ville => {
          if (unique.has(ville)) {
            return false;
          } else {
            unique.add(ville);
            return true;
          }
        });
    },
    biens() {
      return this.$store.state.biens;
    },
    filteredQuartiers() {
      return this.datasQuartier.filter(item => item.ville === this.biens.rville);
    }
  },
  data() {
    return {
      isOpen: false,
      activeTab: 'search', // Onglet actif par défaut
      value1: [1, 180],
      filtre_list: [
        { libelle: 'Piscine', icon: 'fa-solid fa-water-ladder', active: !!this.$store.state.biens.piscine },
        { libelle: 'Internet', icon: 'fa-solid fa-wifi', active: !!this.$store.state.biens.internet },
        { libelle: 'Balcon', icon: 'fa-solid fa-dungeon', active: !!this.$store.state.biens.balcon },
        { libelle: 'Jaccuzi', icon: 'fa-solid fa-hot-tub-person', active: !!this.$store.state.biens.jaccuzi },
        { libelle: 'Parking', icon: 'fa-solid fa-square-parking', active: !!this.$store.state.biens.parking },
        { libelle: 'Bord Mer', icon: 'fa-solid fa-water', active: !!this.$store.state.biens.bord_mer },
        { libelle: 'Bord Route', icon: 'fa-solid fa-road-bridge', active: !!this.$store.state.biens.bord_route },
        { libelle: 'Climatisation', icon: 'fa-solid fa-fan', active: !!this.$store.state.biens.climatisation },
        { libelle: 'Netflix', icon: 'fa-solid fa-n', active: !!this.$store.state.biens.netflix },
        { libelle: 'Moustiquaire', icon: 'fa-solid fa-mosquito-net', active: !!this.$store.state.biens.moustiquaire },
        { libelle: 'Réfrigérateur', icon: 'fa-solid fa-box', active: !!this.$store.state.biens.refrigerateur },
        { libelle: 'Lave machine', icon: 'fa-solid fa-soap', active: !!this.$store.state.biens.lave_machine },
      ],
      filters: {
        piscine: false,
        internet: false,
        balcon: false,
        jaccuzi: false,
        parking: false,
        bordMer: false,
        bordRoute: false,
        climatisation: false,
        netflix: false,
        moustiquaire: false,
        refrigerateur: false,
        laveMachine: false,
      },
      datasQuartier: [],
      page: 1,
      datas: [],
      datas2: [],
      selectedDate: [null, null],
      destination: 'Abidjan',
      adulte: 1,
      enfant: 1,
      Rville: 'Abidjan',
      Rquartier: '0',
      Rtype: '0',
      valeurRecherche: '',
      selectedOption: '',
      dateDebut: '',
      dateFin: '',
      listZone: [
        {libelle: 'Studio'},
        {libelle: '2 Pièces'},
        {libelle: '3 Pièces'},
        {libelle: '4 Pièces'},
        {libelle: 'PLUS'}
      ]
    };
  },
  methods: {
    getTodayDate() {
      return new Date().toISOString().split('T')[0];
    },

	formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },

	changeVille() {
			this.biens.rquartier = "0";
	},

	updatePriceRange() {
      // Ensure minimum value is always at least 1
      if (this.value1[0] < 1) this.value1[0] = 1;
      
      // Ensure maximum value is always greater than minimum
      if (this.value1[1] <= this.value1[0]) this.value1[1] = this.value1[0] + 1;

	  let dataPrix = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000
      };
      this.$store.commit('setBiensPrix', dataPrix);
    },
    
    getSearchPlaceholder() {
      if (this.biens.rville !== '0') {
        let text = this.biens.rville;
        
        if (this.biens.rquartier !== '0') {
          text += `, ${this.biens.rquartier}`;
        }
        
        if (this.biens.rtype !== '0') {
          text += ` • ${this.biens.rtype}`;
        }
        
        return text;
      }
      
      return "Où souhaitez-vous aller ?";
    },
    
    hasActiveFilters() {
      return this.biens.rville !== '0' || this.biens.rquartier !== '0' || this.biens.rtype !== '0' || this.hasActiveAmenities();
    },
    
    hasActiveAmenities() {
      return this.filtre_list.some(item => item.active);
    },
    
    getActiveFiltersCount() {
      let count = 0;
      if (this.biens.rville !== '0') count++;
      if (this.biens.rquartier !== '0') count++;
      if (this.biens.rtype !== '0') count++;
      
      // Ajouter le nombre de filtres d'équipements actifs
      this.filtre_list.forEach(item => {
        if (item.active) count++;
      });
      
      return count;
    },
    
    incrementAdults() {
      if (this.rechercheInfos.adulte < 10) {
        const info = { ...this.rechercheInfos, adulte: this.rechercheInfos.adulte + 1 };
        this.$store.commit('setRechercheInfos', info);
      }
    },
    
    decrementAdults() {
      if (this.rechercheInfos.adulte > 1) {
        const info = { ...this.rechercheInfos, adulte: this.rechercheInfos.adulte - 1 };
        this.$store.commit('setRechercheInfos', info);
      }
    },
    
    incrementChildren() {
      if (this.rechercheInfos.enfant < 10) {
        const info = { ...this.rechercheInfos, enfant: this.rechercheInfos.enfant + 1 };
        this.$store.commit('setRechercheInfos', info);
      }
    },
    
    decrementChildren() {
      if (this.rechercheInfos.enfant > 0) {
        const info = { ...this.rechercheInfos, enfant: this.rechercheInfos.enfant - 1 };
        this.$store.commit('setRechercheInfos', info);
      }
    },
    
    searchData() {
      const info = {
        destination: this.rechercheInfos.destination,
        du: this.rechercheInfos.du,
        au: this.rechercheInfos.au,
        adulte: this.rechercheInfos.adulte,
        enfant: this.rechercheInfos.enfant,
      }
      this.$store.commit('setRechercheInfos', info);
    },
    
    fetchQuartier() {
      this.$api.getQuartier2()
        .then(response => {
          this.datasQuartier = response.data.data;
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        });
    },
    
    openActionSheet() {
      this.isOpen = true;
      document.body.style.overflow = 'hidden';
    },
    
    closeActionSheet() {
      this.isOpen = false;
      document.body.style.overflow = '';
    },
    
    toggleActive(item) {
      item.active = !item.active;
      this.updateFilters(item);
    },
    
    updateFilters(item) {
      switch(item.libelle.toLowerCase()) {
        case 'piscine':
          this.filters.piscine = item.active ? 1 : 0;
          break;
        case 'internet':
          this.filters.internet = item.active ? 1 : 0;
          break;
        case 'balcon':
          this.filters.balcon = item.active ? 1 : 0;
          break;
        case 'jaccuzi':
          this.filters.jaccuzi = item.active ? 1 : 0;
          break;
        case 'parking':
          this.filters.parking = item.active ? 1 : 0;
          break;
        case 'bord mer':
          this.filters.bordMer = item.active ? 1 : 0;
          break;
        case 'bord route':
          this.filters.bordRoute = item.active ? 1 : 0;
          break;
        case 'climatisation':
          this.filters.climatisation = item.active ? 1 : 0;
          break;
        case 'netflix':
          this.filters.netflix = item.active ? 1 : 0;
          break;
        case 'moustiquaire':
          this.filters.moustiquaire = item.active ? 1 : 0;
          break;
        case 'réfrigérateur':
          this.filters.refrigerateur = item.active ? 1 : 0;
          break;
        case 'lave machine':
          this.filters.laveMachine = item.active ? 1 : 0;
          break;
      }

      let data = {
        piscine: this.filters.piscine,
        internet: this.filters.internet,
        balcon: this.filters.balcon,
        jaccuzi: this.filters.jaccuzi,
        parking: this.filters.parking,
        bord_mer: this.filters.bordMer,
        bord_route: this.filters.bordRoute,
        climatisation: this.filters.climatisation,
        netflix: this.filters.netflix,
        moustiquaire: this.filters.moustiquaire,
        refrigerateur: this.filters.refrigerateur,
        lave_machine: this.filters.laveMachine
      }

      this.$store.commit('setFilter', data);
    },
    
    ListeFiltre2() {
      let dataSearch = {
        ville: this.biens.rville,
        commune: this.biens.rquartier,
        type: this.biens.rtype,
      };
	  this.closeActionSheet();

	  let dataPrix = {
		prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
	  };

	  this.$store.commit('setBiensSearch', dataSearch);
      this.$store.commit('setBiensPrix', dataPrix);
      const loading = ElLoading.service({
        lock: true,
        text: 'Recherche en cours...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      
      let datafilter = {
        piscine: this.filters.piscine,
        internet: this.filters.internet,
        balcon: this.filters.balcon,
        jaccuzi: this.filters.jaccuzi,
        parking: this.filters.parking,
        bord_mer: this.filters.bordMer,
        bord_route: this.filters.bordRoute,
        climatisation: this.filters.climatisation,
        netflix: this.filters.netflix,
        moustiquaire: this.filters.moustiquaire,
        refrigerateur: this.filters.refrigerateur,
        lave_machine: this.filters.laveMachine,
      }
      this.$store.commit('setFilter', datafilter);

      const postData = {
        prix_min: this.biens.prix_min,
        prix_max: this.biens.prix_max,
        type: this.biens.rtype,
        ville: this.biens.rville,
        parpage: 10,
        commune: this.biens.rquartier,
        piscine: this.filters.piscine,
        internet: this.filters.internet,
        balcon: this.filters.balcon,
        jaccuzi: this.filters.jaccuzi,
        parking: this.filters.parking,
        bord_mer: this.filters.bordMer,
        bord_route: this.filters.bordRoute,
        climatisation: this.filters.climatisation,
        netflix: this.filters.netflix,
        moustiquaire: this.filters.moustiquaire,
        refrigerateur: this.filters.refrigerateur,
        lave_machine: this.filters.laveMachine,
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }

	  this.biens.datas = null;
      
      const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page='+ this.page +'&id='+ randomId, postData)
        .then(response => {
          let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
            rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage
          }
          this.$store.commit('setDatas', data);
          loading.close();
          this.closeActionSheet();
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
          loading.close();
          this.closeActionSheet();
        });
    },
  },
  mounted() {
    this.fetchQuartier();
	this.changeVille();
    
    // Initialiser les filtres à partir du store
    this.filtre_list.forEach(item => {
      const key = item.libelle.toLowerCase();
      switch(key) {
        case 'piscine':
          item.active = !!this.$store.state.biens.piscine;
          this.filters.piscine = item.active ? 1 : 0;
          break;
        case 'internet':
          item.active = !!this.$store.state.biens.internet;
          this.filters.internet = item.active ? 1 : 0;
          break;
        case 'balcon':
          item.active = !!this.$store.state.biens.balcon;
          this.filters.balcon = item.active ? 1 : 0;
          break;
        case 'jaccuzi':
          item.active = !!this.$store.state.biens.jaccuzi;
          this.filters.jaccuzi = item.active ? 1 : 0;
          break;
        case 'parking':
          item.active = !!this.$store.state.biens.parking;
          this.filters.parking = item.active ? 1 : 0;
          break;
        case 'bord mer':
          item.active = !!this.$store.state.biens.bord_mer;
          this.filters.bordMer = item.active ? 1 : 0;
          break;
        case 'bord route':
          item.active = !!this.$store.state.biens.bord_route;
          this.filters.bordRoute = item.active ? 1 : 0;
          break;
        case 'climatisation':
          item.active = !!this.$store.state.biens.climatisation;
          this.filters.climatisation = item.active ? 1 : 0;
          break;
        case 'netflix':
          item.active = !!this.$store.state.biens.netflix;
          this.filters.netflix = item.active ? 1 : 0;
          break;
        case 'moustiquaire':
          item.active = !!this.$store.state.biens.moustiquaire;
          this.filters.moustiquaire = item.active ? 1 : 0;
          break;
        case 'réfrigérateur':
          item.active = !!this.$store.state.biens.refrigerateur;
          this.filters.refrigerateur = item.active ? 1 : 0;
          break;
        case 'lave machine':
          item.active = !!this.$store.state.biens.lave_machine;
          this.filters.laveMachine = item.active ? 1 : 0;
          break;
      }
    });
    
    // Set dateDebut to tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    // Set dateFin to the day after tomorrow
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    
    // Initialize dates if not already set
    if (!this.rechercheInfos.du) {
      const info = { 
        ...this.rechercheInfos, 
        du: tomorrow.toISOString().substr(0, 10),
        au: dayAfterTomorrow.toISOString().substr(0, 10)
      };
      this.$store.commit('setRechercheInfos', info);
    }
  }
};
</script>

<style>
/* Styles globaux pour le dialog de recherche */
.search-dialog-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 999999 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100vw !important;
  height: 100vh !important;
}

.search-fullpage {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: white !important;
  z-index: 999999 !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  width: 100vw !important;
  height: 100vh !important;
}
</style>

<style scoped>
/* Variables de couleurs et de design */
:root {
  --primary-color: #FF385C;
  --primary-dark: #E31C5F;
  --secondary-color: #00A699;
  --accent-color: #d19100;
  --text-color: #222222;
  --text-light: #717171;
  --background-color: #F7F7F7;
  --border-color: #DDDDDD;
  --shadow-color: rgba(0, 0, 0, 0.08);
  --shadow-hover: rgba(0, 0, 0, 0.15);
  --border-radius: 16px;
  --transition: all 0.3s ease;
}

/* Styles généraux */
.search-container {
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 16px;
  perspective: 1000px; /* Ajoute une perspective pour l'effet 3D */
}

/* Barre de recherche principale */
.search-bar-wrapper {
  position: relative;
  margin-bottom: 16px;
  padding: 8px;
  transform-style: preserve-3d;
  perspective: 1000px;
  margin-top: -15px;
}

.search-bar {
  display: flex;
  align-items: center;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 24px;
  box-shadow: 
    0 10px 25px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(0, 0, 0, 0.08),
    0 4px 6px rgba(0, 0, 0, 0.06),
    inset 0 -2px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1px solid rgba(255, 255, 255, 0.8);
  position: relative;
  overflow: hidden;
  transform: translateZ(20px); /* Effet de sortie de l'écran */
  backdrop-filter: blur(5px);
}

.search-bar:hover {
  transform: translateZ(30px) translateY(-5px);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.12),
    0 10px 20px rgba(0, 0, 0, 0.1),
    0 6px 10px rgba(0, 0, 0, 0.08),
    inset 0 -2px 5px rgba(0, 0, 0, 0.03);
}

.search-bar:active {
  transform: translateZ(10px) translateY(-2px);
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Zone de saisie */
.search-input-area {
  display: flex;
  align-items: center;
  padding: 5px;
  flex: 1;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: linear-gradient(135deg, #e6a300, #d19100);
  margin-right: 16px;
  box-shadow: 0 4px 8px rgba(209, 145, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-bar:hover .search-icon {
  transform: scale(1.05) translateZ(5px);
  box-shadow: 0 6px 12px rgba(209, 145, 0, 0.3);
}

.search-icon i {
  color: white;
  font-size: 18px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search-placeholder {
  flex: 1;
}

.search-text {
  font-size: 15px;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

.search-filters-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--accent-color);
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.search-bar:hover .search-text {
  color: #000;
}

/* Bouton de recherche principal */
.search-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var(--accent-color), #b37d00);
  color: white;
  border: none;
  padding: 0 24px;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  min-width: 120px;
}

.search-submit-button:hover {
  background: linear-gradient(to right, #e6a300, #cc8f00);
}

.search-submit-button i {
  margin-right: 8px;
  font-size: 16px;
}

/* Tabs de navigation */
.search-tabs {
  display: flex;
  margin-bottom: 5px;
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 4px;
}

.tab-button {
  flex: 1;
  padding: 14px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-light);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
}

.tab-button.active {
  color: white;
  background-color: #b9a069;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tab-content {
  padding: 8px 0;
}

/* En-tête du panneau plein écran */
.fullpage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.fullpage-header h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
}

.close-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: none;
  display:  36px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.close-button:hover {
  background-color: #EBEBEB;
}

.close-button i {
  font-size: 16px;
  color: var(--text-color);
}

/* Corps du formulaire plein écran */
.fullpage-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f7f7f7;
}

.search-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.form-group label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #d19100;
  margin-bottom: 12px;
}

.form-group label i {
  margin-right: 10px;
  color: var(--accent-color);
  font-size: 18px;
}

.form-control {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  font-size: 16px;
  color: var(--text-color);
  background-color: white;
  transition: var(--transition);
  appearance: none;
}

.form-control:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(209, 145, 0, 0.2);
}

/* Select avec flèche personnalisée */
.select-wrapper {
  position: relative;
}

.select-arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-light);
  pointer-events: none;
}

/* Dates */
.dates-group {
  margin-bottom: 10px;
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 12px;
}

.date-input-wrapper {
  flex: 1;
  position: relative;
}

.date-input {
  padding-top: 24px;
}

.date-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 12px;
  color: var(--text-light);
}

.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light);
}

/* Voyageurs */
.guests-group {
  margin-bottom: 24px;
}

.guests-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.guest-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
}

.guest-label {
  font-size: 16px;
  color: var(--text-color);
}

.counter-controls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.counter-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.counter-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.counter-btn:not(:disabled):hover {
  border-color: var(--text-color);
}

.counter-value {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  min-width: 24px;
  text-align: center;
}

/* Gamme de prix */
.price-range {
  padding: 10px 0;
}

/* Grille d'équipements */
.amenities-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.amenity-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: white;
  transition: var(--transition);
  cursor: pointer;
  height: 80px;
}

.amenity-button:hover {
  background-color: #f5f5f5;
}

.amenity-button.active {
  background-color: rgba(209, 145, 0, 0.1);
  border-color: var(--accent-color);
}

.amenity-icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: var(--accent-color);
}

.amenity-text {
  font-size: 12px;
  text-align: center;
  color: var(--text-color);
}

/* Bouton de recherche */
.search-button-group {
  background: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 80px;
}

.search-button {
  width: 100%;
  padding: 16px;
  background: #cc8f00;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.search-button:hover {
  background: #cc8f00;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(209, 145, 0, 0.3);
}

.search-button i {
  font-size: 16px;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Responsive */
@media (max-width: 480px) {
  .date-inputs {
    flex-direction: row;
  }
  
  .date-separator {
    display: none;
  }
  
  .search-submit-button {
    min-width: auto;
    padding: 0 16px;
  }
  
  .search-submit-button span {
    display: none;
  }
  
  .search-submit-button i {
    margin-right: 0;
  }
  
  .amenities-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.price-range-container {
  padding: 10px 0;
}

.price-display {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: 500;
}

.price-slider {
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  height: 4px;
  background: #ddd;
  border-radius: 2px;
  outline: none;
}

.price-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--accent-color);
  cursor: pointer;
}

.price-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--accent-color);
  cursor: pointer;
  border: none;
}
</style>