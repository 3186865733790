import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Accueil,
    meta: {
      title: 'Page Accueil',
      description: 'Description de la page Accueil',
      twitterImage: 'https://gratisography.com/wp-content/uploads/2024/11/gratisography-augmented-reality-800x525.jpg',
      ogImage: 'https://gratisography.com/wp-content/uploads/2024/11/gratisography-augmented-reality-800x525.jpg',
    }
  },
  {
    path: '/detail/:param',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    meta: {
      title: 'Page détail',
      description: 'Description de la page Détail',
      twitterImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
      ogImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
    }
  },
  {
    path: '/paiement_validation/:param',
    name: 'paiement_validation',
    component: () => import('../components/detail/detail_mobile/validation_paiement.vue'),
    meta: {
      title: 'Page détail',
      description: 'Description de la page Détail',
      twitterImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
      ogImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
    }
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('../components/accueil/accueil_mobile/Help.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../components/accueil/accueil_web/help.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../components/accueil/accueil_mobile/PrivacyPolicy.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../components/accueil/accueil_web/privacy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('../components/accueil/accueil_mobile/TermsOfService.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../components/accueil/accueil_web/termsService.vue')
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('../components/detail/detail_mobile/SummaryPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/dashboard/dashboard_login/LoginIndex.vue')
  },
  {
    path: '/login_web',
    name: 'login_web',
    component: () => import('../components/dashboard/dashboard_login/LoginWeb.vue')
  },
  {
    path: '/inscription',
    name: 'inscription',
    component: () => import('../components/dashboard/dashboard_login/InscriptionIndex.vue')
  },
  {
    path: '/inscription_web',
    name: 'inscription_web',
    component: () => import('../components/dashboard/dashboard_login/InscriptionWeb.vue')
  },

  {
    path: '/nouvelle_enregistrement',
    name: 'nouvelle_enregistrement',
    component: () => import('../views/dashboard/Formulaire.vue')
  },
  {
    path: '/newform',
    name: 'newform',
    component: () => import('../components/dashboard/dashboard_form/NewForm.vue')
  },
  {
    path: '/verifform/:param',
    name: 'verifform',
    component: () => import('../components/dashboard/dashboard_form/VerifForm.vue'),
    meta: {
      title: 'Page Vérification',
      description: 'Description de la page Détail',
      twitterImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
      ogImage: 'https://img.freepik.com/photos-gratuite/dock-bois-brun_198523-110.jpg',
    }
  },
  {
    path: '/newform_mobile',
    name: 'newform_mobile',
    component: () => import('../components/dashboard/dashboard_form/NewFormMobile.vue')
  },
  // DASHBOARD //
  {
    path: '/dashboard_mobile',
    name: 'dashboard_mobile',
    component: () => import('../components/dashboard/dashboard_menu/MenuIndex.vue')
  },
  {
    path: '/dashboard_w',
    name: 'dashboard_w',
    component: () => import('../components/dashboard_web/dashboard.vue')
  },
  {
    path: '/reservation_u_w',
    name: 'reservation_u_w',
    component: () => import('../components/dashboard_web/reservations.vue')
  },
  {
    path: '/reservation_a_w',
    name: 'reservation_a_w',
    component: () => import('../components/dashboard_web/reservation_admin.vue')
  },
  {
    path: '/residences_w',
    name: 'residences_w',
    component: () => import('../components/dashboard_web/residences.vue')
  },
  {
    path: '/dashboard_residence',
    name: 'dashboard_residence',
    component: () => import('../components/dashboard/dashboard_residence/ResidenceIndex.vue')
  },
  {
    path: '/dashboard_mes_favoris',
    name: 'dashboard_mes_favoris',
    component: () => import('../components/dashboard/dashboard_residence/MesFavoris.vue')
  },
  {
    path: '/dashboard_web_favoris',
    name: 'dashboard_web_favoris',
    component: () => import('../components/dashboard_web/favoris.vue')
  },
  {
    path: '/dashboard_web_profile',
    name: 'dashboard_web_profile',
    component: () => import('../components/dashboard_web/profile.vue')
  },
  {
    path: '/chatbot',
    name: 'chatbot',
    component: () => import('../components/dashboard_web/chatbot.vue')
  },
  {
    path: '/dashboard_reservation',
    name: 'dashboard_reservation',
    component: () => import('../components/dashboard/dashboard_reservation/ReservationIndex.vue')
  },
  {
    path: '/dashboard_mes_reservations',
    name: 'dashboard_mes_reservations',
    component: () => import('../components/dashboard/dashboard_reservation/MesReservationsIndex.vue')
  },
  {
    path: '/dashboard_paiement',
    name: 'dashboard_paiement',
    component: () => import('../components/dashboard/dashboard_paiement/PaiementIndex.vue')
  },
  {
    path: '/dashboard_quartier',
    name: 'dashboard_quartier',
    component: () => import('../components/dashboard/Quartier.vue')
  },
  {
    path: '/dashboard_parametre',
    name: 'dashboard_parametre',
    component: () => import('../components/dashboard/dashboard_parametre/ParametreIndex.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
  // Mettre à jour le titre
  document.title = to.meta.title;

  // Mettre à jour la description
  const descriptionTag = document.querySelector('meta[name="description"]');
  if (descriptionTag) {
    descriptionTag.setAttribute('content', to.meta.description);
  }

  // Mettre à jour twitter:image
  const twitterImageTag = document.querySelector('meta[name="twitter:image"]');
  if (twitterImageTag && to.meta.twitterImage) {
    twitterImageTag.setAttribute('content', to.meta.twitterImage);
  } else if (!twitterImageTag && to.meta.twitterImage) {
    // Créer la balise <meta> si elle n'existe pas
    const newTwitterImageTag = document.createElement('meta');
    newTwitterImageTag.setAttribute('name', 'twitter:image');
    newTwitterImageTag.setAttribute('content', to.meta.twitterImage);
    document.head.appendChild(newTwitterImageTag);
  }

  // Mettre à jour og:image
  const ogImageTag = document.querySelector('meta[property="og:image"]');
  if (ogImageTag && to.meta.ogImage) {
    ogImageTag.setAttribute('content', to.meta.ogImage);
  } else if (!ogImageTag && to.meta.ogImage) {
    // Créer la balise <meta> si elle n'existe pas
    const newOgImageTag = document.createElement('meta');
    newOgImageTag.setAttribute('property', 'og:image');
    newOgImageTag.setAttribute('content', to.meta.ogImage);
    document.head.appendChild(newOgImageTag);
  }
});

export default router
