<template>
  <div>
    <div style="width: 100%;">
      <nav class="navbar">
        <div class="py-0" style="margin-left: -10px;">
          <div>
            <div class="position-fixed z-3 w-100 top-0" style="display: flex; align-items: center; justify-content: space-between;">
              <span class="mt-2 mb-1 ml-4">
                <router-link to="/" style="margin-left: 10px; color: #d19100">
                  <img src="../../../assets//images/logosarezide.png" width="160" style="margin-top: -25px"/>
                </router-link>
              </span>
              <span class="mt-2 mb-1 mr-4 d-flex align-items-center">
                <a @click="openWhatsApp" target="_blank" rel="noopener noreferrer" class="mr-3">
                  <i class="fab fa-whatsapp" style="font-size: 1.7rem; color: green;"></i>
                </a>
                <a href="https://web.facebook.com/sarezide" target="_blank" rel="noopener noreferrer" class="mr-3">
                  <i class="fab fa-facebook" style="font-size: 1.5rem; color: blue;"></i>
                </a>
                <a href="https://www.tiktok.com/@sarezide.ci" target="_blank" rel="noopener noreferrer" class="mr-3">
                  <i class="fab fa-tiktok" style="font-size: 1.5rem; color: #050a30;"></i>
                </a>
                <i class="fa-solid fa-bars" @click="toggleMenu" style="font-size: 1.5rem; color: #d19100; cursor: pointer;"></i>
              </span>
            </div>
          </div>
        </div>

        <!-- Menu déroulant -->
        <div v-if="isMenuOpen" class="mobile-menu" @click.self="closeMenu">
          <router-link to="/help-center" @click="closeMenu">Centre d'aide</router-link>
          <router-link to="/privacy-policy" @click="closeMenu">Politique de confidentialité</router-link>
          <router-link to="/terms-of-service" @click="closeMenu">Conditions Générales</router-link>
          <!-- Ajoutez d'autres liens selon vos besoins -->
        </div>

        <!-- Le reste de votre code navbar... -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccueilNavbar',
  data() {
    return {
      destination: '',
      datePeriode: [],
      isActive: false,
      isMenuOpen: false,
      loading: false,
      options: [{
          ville: 'Abidjan',
          code: 'Tom'
        }, {
          ville: 'Assinie',
          code: 'Tom'
        }, {
          ville: 'Bassam',
          code: 'Tom'
        }, {
          ville: 'Bouaké',
          code: 'Tom'
        }],
    };
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
    onDestinationBlur() {
      if (this.destination) {
        this.$refs.datePicker.focus();
      }
    },
    openWhatsApp() {
      const phoneNumber = '2250153676062';
      const reservationDetails = `Bonjour, je vous écris du site Sarezide.ci`;
      const message = encodeURIComponent(reservationDetails);
      const url = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(url, '_blank');
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.addEventListener('click', this.closeMenuOutside);
      } else {
        document.removeEventListener('click', this.closeMenuOutside);
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.removeEventListener('click', this.closeMenuOutside);
    },
    closeMenuOutside(event) {
      if (!event.target.closest('.mobile-menu') && !event.target.closest('.fa-bars')) {
        this.closeMenu();
      }
    },
    remoteMethod(query) {
      // Implémentez ici la logique pour charger les options de destination
      console.log('Searching for:', query);
    }
  },
};
</script>

<style scoped>
.navbar {
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  padding: 0px;
}
.navbar-item img {
  width: 200px;
}
.responsive-input {
  max-width: 500px;
  width: 100%;
}
.centered-navbar-start {
  display: flex;
  justify-content: center;
  width: 100%;
}
.mobile-menu {
  position: fixed;
  top: 53px; /* Ajustez selon la hauteur de votre navbar */
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1001;
}
.mobile-menu a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #050a30;
  border-bottom: 1px solid #eee;
}
.mobile-menu a:last-child {
  border-bottom: none;
}
</style>

