<template>
    <div class="summary-page">
      <div class="page-header">
        <p class="title-detail" style="font-family: 'Montserrat', sans-serif; font-size: 20px"></p>
        <button class="button is-rounded is-small close-button" @click="closeLogin">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
  
      <!-- Bloc 2 : Séjour -->
      <div class="block" style="margin-top: -1px">
        <h3 class="title is-5" style="text-align: center; font-size: 30px">Inscription</h3>
        <p style="text-align: center;margin-top: -10px">J'ai déjà un compte, <el-link @click="LoginPage()" type="primary" style="font-size: 19px">se connecter</el-link></p>
        <div style="margin-top:30px" v-if="parEmail">
          
            <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input
                  v-model="login.nom"
                    class="input"
                    type="text"
                    placeholder="Nom"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
            </div>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.prenom"
                    class="input"
                    type="text"
                    placeholder="Prenom"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
            </div>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.contact"
                    class="input"
                    type="text"
                    placeholder="Contact"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-phone"></i>
                  </span>
                </div>
            </div>

            <br/>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.email"
                    class="input"
                    type="text"
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </div>
            </div>
  
            <div class="field">
                <p class="control has-icons-left">
                <input v-model="login.password" class="input" type="password" placeholder="Mot de passe" />
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
                </p>
            </div>

            <div class="field">
                <p class="control has-icons-left">
                <input v-model="login.passwordverif" class="input" type="password" placeholder="Mot de passe confirmation" />
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
                </p>
            </div>
  
        </div>
      </div>
  
      <div style="width: 100%" v-if="parEmail">
        <button style="width: 100%" class="button is-primary is-fullwidth" @click="verif()">M'inscrire</button>
      </div>

      <button
        v-if="!parEmail"
        :disabled="loading"
        @click="parEmail = true"
        class="facebook-login-button"
      >
        <div v-if="loading">
          <ActivityIndicator size="small" color="orange" />
          <span class="ml-3">Connexion en cours...</span>
        </div>
        <div v-else class="button-content">
          <img :src="logo.facebook" alt="Facebook logo" class="button-icon" />
          <span class="button-text">M'inscrire avec mon Email</span>
        </div>
      </button>

      <el-divider />

      <!-- Bouton de connexion Google -->
      <GoogleLogin
        v-if="!parEmail"
        style="margin-bottom: 70px;"
        :callback="handleGoogleLogin"
        :clientId="'436764491754-4f2jbsd527ub8bkbmbjv6dd5jlnqr0sb.apps.googleusercontent.com'"
        class="google-login-button"
      >
        <div v-if="loading">
          <ActivityIndicator size="small" color="orange" />
          <span class="ml-3">Connexion en cours...</span>
        </div>
        <div v-else class="button-content">
          <img :src="logo.google" alt="Google logo" class="button-icon" />
          <span class="button-text">M'inscrire avec Google</span>
        </div>
      </GoogleLogin>

      

    </div>
  </template>
  
  <script>
  import { ElLoading, ElNotification } from 'element-plus'
  import axios from 'axios';
  export default {
    name: 'SummaryPage',
    props: {
      web: {
        type: Number, // Indique si la propriété est requise
      }
    },
    data() {
      return {
        login: {
            email: '',
            password: '',
            nom: '',
            prenom: '',
            contact: '',
            passwordverif: '',
        },
        parEmail: false,
        loading: false,
        logo: {
          facebook: 'https://png.pngtree.com/png-clipart/20191120/original/pngtree-email-icon-png-image_5065641.jpg',
          google: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png', // Remplacez par votre logo Google
        },
        decryptedData: null,
        reserve : {
          reference: '',
          nom: '',
          prenom: '',
          contact: '',
          email: '',
          du: '',
          au: '',
          nbjour: 0,
          avance: 0,
          residence: 0,
          paiement: '',
          nbper: 2,
          nbenf: 1,
          taxi: 'Non',
          pays: 'Côte d\'Ivoire',
          statut_reserve: 'En cours',
          commentaire_reserve: '',
          user_reserve: 0,
          proprietaire: 0
        },
        showDatePicker: false,
        date: null,
        activeDate: false,
        invite: 1,
        dateArrivee: '', // Pour stocker la date d'arrivée sélectionnée
        dateDepart: '', // Pour stocker la date de départ sélectionnée
        nombrePersonnes: 1, // Pour stocker le nombre de personnes, par défaut 1
        formattedDate: '', // Pour stocker la date formatée
      };
    },
    computed: {
      rechercheInfos() {
        return this.$store.state.rechercheInfos;
      },
      biens() {
        return this.$store.state.donneesBiens; // Assurez-vous que donneesBiens contient les détails nécessaires
      },
      periode() {
        // Exemple de calcul de la période (à ajuster selon votre logique)
        return `${this.dateArrivee} au ${this.dateDepart}`;
      },
      calculerTotal() {
        // Méthode pour calculer le total en fonction de la période sélectionnée
        // Vous pouvez implémenter votre logique de calcul ici
        return this.biens.tarif_jour; // Exemple simplifié, ajustez selon votre logique réelle
      }
    },
    watch: {
      date: {
        handler() {
          this.updateFormattedDate();
        },
        deep: true,
      },
    },
    methods: {
      async handleGoogleLogin(response) {
      this.loading = true;

      try {
        // Affichez la réponse Google dans la console
        console.log('Réponse Google:', response);

        // Extrayez le code d'autorisation
        const code = response.code;

        // Affichez le code dans la console
        console.log('Code Google:', code);

        // Échangez le code contre un token d'accès
        const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
          code: code,
          client_id: '436764491754-4f2jbsd527ub8bkbmbjv6dd5jlnqr0sb.apps.googleusercontent.com', // Remplacez par votre Client ID
          client_secret: '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', // Remplacez par votre Client Secret
          redirect_uri: 'http://localhost:8081', // Remplacez par votre URL de redirection
          grant_type: 'authorization_code',
        });

        // Affichez la réponse du token dans la console
        console.log('Réponse du token:', tokenResponse.data);

        // Extrayez le token d'accès
        const accessToken = tokenResponse.data.access_token;

        // Affichez le token d'accès dans la console
        console.log('Token d\'accès:', accessToken);

        // Récupérez les informations du profil utilisateur
        const userProfile = await this.getUserProfile(accessToken);
        userProfile.network = 'Google';

        // Affichez les informations du profil utilisateur dans la console
        console.log('Profil utilisateur Google:', userProfile);

        // Envoyez les informations du profil utilisateur à votre backend

        const backendResponse = await this.$api.postInscriptionReseau(userProfile);

        if (backendResponse.data.status === 'success') {

          this.closeLogin();
          this.$store.commit('ConnexionAgent', backendResponse.data.data);
          this.LoginPage();
          this.$router.push({ path: '/dashboard_mobile' });

          ElNotification({
            title: 'Succès',
            message: 'Connexion réussie via Google.',
            type: 'success',
          });
        } else {
          ElNotification({
            title: 'Erreur',
            message: 'Impossible de se connecter via Google.',
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Erreur lors de la connexion Google:', error);
        ElNotification({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la connexion Google.',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    async getUserProfile(accessToken) {
      try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Retournez les informations du profil utilisateur
        return userInfoResponse.data;
      } catch (error) {
        console.error('Erreur lors de la récupération du profil utilisateur:', error);
        return null;
      }
    },
        verif() {
            if (this.login.password === this.login.passwordverif) {
                this.submitLogin();
            } else {
                ElNotification({
                title: 'Erreur',
                message: 'les mots de passe sont pas identique',
                type: 'error',
                });
            }
        },
        async submitLogin() {
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading...',
            background: 'rgba(0, 0, 0, 0.7)',
        });

        try {
            // Envoie des données de connexion au serveur
            const response = await this.$api.postInscription({ email: this.login.email, password: this.login.password, nom: this.login.nom, prenom: this.login.prenom, contact: this.login.contact });

            if (response.data.valide === 1) {

            ElNotification({
                title: 'Succès',
                message: response.data.mes || 'Inscription réussie',
                type: 'success',
            });

            setTimeout(() => {
              this.closeLogin();
            }, 1000);

            } else {

              ElNotification({
                  title: 'Erreur',
                  message: response.data.mes || 'Erreur',
                  type: 'error',
              });

            }
        } catch (error) {
            console.error('Erreur de requête:', error);
        } finally {
            loading.close();
        }
        },
        goToLogin() {
        this.$router.push({ path: '/login' });
      },
      generateReservationReference() {
        const date = new Date();
        
        // Format de la date : aammjj (année, mois, jour)
        const year = String(date.getFullYear()).slice(-2); // Les deux derniers chiffres de l'année
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois (ajouter +1 car getMonth() est 0-indexed)
        const day = String(date.getDate()).padStart(2, '0'); // Jour
        
        // Ajouter l'heure et la minute
        const hours = String(date.getHours()).padStart(2, '0'); // Heure
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minute
        
        // Générer une partie unique/aléatoire
        const uniquePart = Date.now().toString().slice(-6); // Par exemple, les 6 derniers chiffres du timestamp

        // Combiner la date, l'heure, la minute et la partie unique
        const reference = `${year}${month}${day}${hours}${minutes}${uniquePart}`;
        
        return reference;
      },
      retourPagePrecedente() {
        // Méthode pour retourner à la page précédente
        this.$router.go(-1); // Utilisation de Vue Router pour revenir en arrière
      },
      LoginPage() {
        let statut = true;
        this.$store.commit('setLoginPage', statut);
      },
      closeLogin() {
        let statut = false;
        this.$store.commit('setLogin', statut);
      },
      scrollToTop() {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' // Permet un défilement fluide
          });
        },
      formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },
      updateFormattedDate() {
        if (this.rechercheInfos.du && this.rechercheInfos.au) {
          const startDate = new Date(this.rechercheInfos.du);
          const endDate = new Date(this.rechercheInfos.au);
          const options = { day: '2-digit', month: 'short' };
  
          if (startDate.getMonth() === endDate.getMonth()) {
            this.formattedDate = `${startDate.getDate()}-${endDate.toLocaleDateString('fr-FR', options)}`;
          } else {
            const startOptions = { day: '2-digit', month: 'short' };
            const endOptions = { day: '2-digit', month: 'short' };
            this.formattedDate = `${startDate.toLocaleDateString('fr-FR', startOptions)} - ${endDate.toLocaleDateString('fr-FR', endOptions)}`;
          }
        } else {
          this.formattedDate = '';
        }
        this.activeDate = false;
      },
      closeDatePopover() {
        this.$refs.datePopover.$emit('close');
      }
    },
    mounted() {
      this.nombrePersonnes = this.rechercheInfos.adulte + this.rechercheInfos.enfant;
      this.updateFormattedDate(); // Mettre à jour la date formatée lors du montage
    }
  };
  </script>
  
  <style scoped>
.summary-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Pour permettre au bouton d'être positionné en absolu */
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrage horizontal */
  width: 100%;
  padding-top: 8px;
  height: 50px;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.google-login-button:hover {
  background-color: #f1f1f1;
}

.google-login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.facebook-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.facebook-login-button:hover {
  background-color: #f1f1f1;
}

.facebook-login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrage horizontal */
  width: 100%; /* Prend toute la largeur du bouton */
}
.button-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.button-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 15px;
}

.page-header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px; /* Ajuste l'espacement si nécessaire */
}

.block {
  margin-top: 100px; /* Ajuste la position du contenu */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px; /* Limite la largeur du contenu si nécessaire */
}

.divider {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
}

.field {
  margin-bottom: 1rem;
  width: 100%;
}
</style>