<template>
    <footer class="bg-gray-100 py-8 mt-auto">
      <div class="container mx-auto px-4">
        <div class="flex flex-col md:flex-row justify-between items-center">
          <!-- Logo et copyright -->
          <div class="mb-4 md:mb-0">
            <h3 class="text-lg font-bold" style="text-align: center;">SaRezide</h3>
            <p class="text-sm text-gray-600">© {{ new Date().getFullYear() }} Tous droits réservés</p>
          </div>
          
          <!-- Liens légaux -->
          <div class="mb-4 md:mb-0" v-if="$isMobile()">
            <ul class="flex flex-wrap gap-4 justify-center">
              <li>
                <a href="/privacy-policy" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Politique de confidentialité
                </a>
              </li>
              <li>
                <a href="/terms-of-service" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Conditions d'utilisation
                 </a>
              </li>
              <li>
                <a href="/help-center" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Centre d'aide
                 </a>
              </li>
            </ul>
          </div>

          <div class="mb-4 md:mb-0" v-else>
            <ul class="flex flex-wrap gap-4 justify-center">
              <li>
                <a href="/privacy" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Politique de confidentialité
                </a>
              </li>
              <li>
                <a href="/terms" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Conditions d'utilisation
                 </a>
              </li>
              <li>
                <a href="/help" class="text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  Centre d'aide
                 </a>
              </li>
            </ul>
          </div>
          
          <!-- Réseaux sociaux -->
          <div class="flex gap-4">
            <!-- Facebook -->
            <a href="https://web.facebook.com/sarezide" class="text-gray-600 hover:text-blue-600 transition-colors" aria-label="Facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/>
              </svg>
            </a>
            <!-- TikTok -->
            <a href="https://www.tiktok.com/@sarezide" class="text-gray-600 hover:text-black transition-colors" aria-label="TikTok">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9 18V5l12-2v13"/>
                <circle cx="6" cy="18" r="3"/>
                <circle cx="18" cy="16" r="3"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooTer'
  }
  </script>
  
  <style scoped>
  /* Styles supplémentaires si nécessaires */
  </style>