<template>
  <div class="app-container">
    <!-- Fixebar avec NavbarWeb et FilterWeb -->
    <div class="fixebar">
      <NavbarWeb2 msg="Welcome to Your Vue.js App" style="border-bottom: 5px solid #d19100; padding-bottom: 20px"/>
    </div>

    <div class="filter-container">
      <FilterWeb msg="Welcome to Your Vue.js App"/>
    </div>

    <!-- Container principal qui contiendra la liste et la carte -->
    <div class="main-container" :class="{ 'split-view': showMap }">
      <!-- Filtre de distance -->

      <!-- ListeWeb avec conteneur scrollable -->
      <div class="list-container" :class="{ 'list-reduced': showMap }">
        <div class="scrollable-list">
          <ListeWeb :msg="datas"/>
          <el-pagination
            class="pagination"
            :current-page="biens.rpage"
            :page-size="10"
            size="large"
            :page-count="biens.total_pages"
            layout="prev, pager, next"
            @current-change="handlePageChange"
            background
          ></el-pagination>
          <Footer />
        </div>
      </div>

      <!-- Bouton pour afficher/masquer la carte -->
      <div class="map-button-container" :class="{ 'map-button-hidden': isButtonHidden }">
        <el-button type="#d19100" style="background-color: #d19100; color: white" class="map-button" @click="toggleMap">
          {{ showMap ? 'Masquer la carte' : 'Afficher la carte' }}
        </el-button>
      </div>

      <!-- Map section (toujours dans le DOM mais visible uniquement quand showMap est true) -->
      <div class="map-container" :class="{ 'map-visible': showMap }">
        <div class="map-header">
          <h3>Carte des résidences</h3>
          <div v-if="lastLocationInfo" class="location-info-banner">
            Dernière position: {{ lastLocationInfo.city }} - {{ lastLocationInfo.neighborhood }}
          </div>
          <div v-if="useDistanceFilter && centerPosition" class="location-info-banner">
            Rayon de recherche: 40km autour de {{ centerPositionName }}
          </div>
        </div>
        <GMapMap
          :center="mapCenter"
          :zoom="12"
          map-type-id="terrain"
          style="width: 100%; height: 100%;"
          ref="gmap"
          @bounds_changed="onMapReady"
        >
          <template v-if="mapReady && $refs.gmap && $refs.gmap.$mapObject">
            <!-- Cercle de 40km autour du centre si le filtre de distance est activé -->
            <GMapCircle
              v-if="useDistanceFilter && centerPosition"
              :center="centerPosition"
              :radius="8000"
              :options="{
                strokeColor: '#d19100',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#d19100',
                fillOpacity: 0.1
              }"
            />
            
            <!-- Marqueur pour le centre si le filtre de distance est activé -->
            <GMapMarker
              v-if="useDistanceFilter && centerPosition"
              :position="centerPosition"
              :options="{
                icon: {
                  url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                }
              }"
            />
            
            <CustomOverlay
              v-for="(residence, index) in biens.datas"
              :key="index"
              :position="residence.position"
              :price="formatMoney(residence.residence.prix_nuit)"
              :map="$refs.gmap.$mapObject"
              @click="showDetails(residence)"
              @dragend="onMarkerDragEnd"
              @locationChanged="onLocationChanged(residence, $event)"
            />
          </template>
        </GMapMap>
      </div>
    </div>

    <!-- Afficher les détails de la résidence -->
    <ResidenceDetails
      v-if="selectedResidence"
      :residence="selectedResidence"
      @close="closeResidenceDetails"
    />
  </div>
</template>

<script>
import CustomOverlay from './pointer.vue';
import ResidenceDetails from './ResidenceDetails.vue';
import { askOpenAI } from '../../../openaiService';
import { ElLoading, ElNotification, ElButton } from 'element-plus';
import NavbarWeb2 from '@/components/accueil/accueil_web/Navbarr.vue';
import FilterWeb from '@/components/accueil/accueil_web/Filter.vue';
import ListeWeb from '@/components/accueil/accueil_web/Liste.vue';
import Footer from '@/views/Footer.vue'

export default {
  name: 'AccueilIndex',
  components: {
    NavbarWeb2,
    FilterWeb,
    ListeWeb,
    CustomOverlay,
    ResidenceDetails,
    ElButton,
    Footer
  },
  computed: {
    biens() {
      return this.$store.state.biens;
    },
    mapCenter() {
      return this.centerPosition || { lat: 5.318790, lng: -4.013572 };
    },
    centerPositionName() {
      if (this.distanceCenter === 'current') {
        return 'Ma position';
      } else if (this.distanceCenter === 'city') {
        return 'Centre ville';
      } else {
        const quartier = this.quartiers.find(q => q.id === this.distanceCenter);
        return quartier ? quartier.nom : 'Position sélectionnée';
      }
    }
  },
  data() {
    return {
      cartePage: 20,
      isButtonHidden: false, // Nouvelle propriété pour contrôler la visibilité du bouton
      residences: [
        {
          name: 'Résidence 1',
          position: { lat: 5.340772, lng: -4.005470 },
          price: '100 000 F',
          image: 'https://via.placeholder.com/150',
          description: 'Une belle résidence avec vue sur la mer.',
          address: 'Adresse 1, Abidjan',
        },
        {
          name: 'Résidence 2',
          position: { lat: 5.309792, lng: -4.005670 },
          price: '150 000 F',
          image: 'https://via.placeholder.com/150',
          description: 'Résidence moderne avec piscine.',
          address: 'Adresse 2, Abidjan',
        },
        // Ajoutez d'autres résidences ici
      ],
      userInput: '',
      response: null,
      datas: [],
      value1: [1, 180],
      select1: '0',
      select2: '0',
      selectedType: '0',
      filters: {
        piscine: '',
        restaurant: '',
        bordureMer: '',
        evenement: '',
        internet: '',
      },
      mapReady: false,
      selectedResidence: null,
      showMap: false, // Contrôle l'affichage de la carte
      lastLocationInfo: null, // Pour stocker les dernières informations de localisation
      
      // Nouveaux champs pour le filtre de distance
      useDistanceFilter: false,
      distanceCenter: 'current',
      centerPosition: null,
      locating: false,
      quartiers: [
        { id: 'cocody', nom: 'Cocody', position: { lat: 5.3599, lng: -3.9673 } },
        { id: 'plateau', nom: 'Plateau', position: { lat: 5.3234, lng: -4.0168 } },
        { id: 'marcory', nom: 'Marcory', position: { lat: 5.3010, lng: -3.9784 } },
        { id: 'treichville', nom: 'Treichville', position: { lat: 5.2922, lng: -4.0050 } },
        { id: 'koumassi', nom: 'Koumassi', position: { lat: 5.2833, lng: -3.9500 } },
        { id: 'portbouet', nom: 'Port-Bouët', position: { lat: 5.2478, lng: -3.9269 } },
        { id: 'yopougon', nom: 'Yopougon', position: { lat: 5.3302, lng: -4.0864 } },
        { id: 'abobo', nom: 'Abobo', position: { lat: 5.4192, lng: -4.0453 } },
        { id: 'adjame', nom: 'Adjamé', position: { lat: 5.3567, lng: -4.0233 } },
        { id: 'bingerville', nom: 'Bingerville', position: { lat: 5.3500, lng: -3.8833 } }
      ],
      // Pour la gestion des requêtes
      currentRequest: null,
      isMapMoving: false,
      mapMoveTimer: null,
    };
  },
  methods: {
    // Nouvelle méthode pour masquer le bouton
    hideButton() {
      this.isButtonHidden = true;
    },
    
    // Nouvelle méthode pour afficher le bouton
    showButton() {
      this.isButtonHidden = false;
    },
    
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    formatMoney(amount) {
      if (isNaN(amount)) {
        return amount;
      }
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'XOF'
      }).format(amount);
    },
    onMapReady() {
      this.mapReady = true;
      console.log('Map is ready');
      
      // Add event listeners to the map
      if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
        const map = this.$refs.gmap.$mapObject;
        
        // Événements de début de mouvement
        map.addListener('dragstart', () => {
          console.log('Map drag started');
          this.isMapMoving = true;
          this.cancelPendingRequest();
        });
        
        map.addListener('zoom_changed', () => {
          console.log('Map zoom changed');
          this.isMapMoving = true;
          this.cancelPendingRequest();
        });
        
        // Événement de fin de mouvement
        map.addListener('idle', () => {
          console.log('Map is idle (movement stopped)');
          
          // Si la carte était en mouvement, attendre un peu puis filtrer
          if (this.isMapMoving) {
            this.isMapMoving = false;
            
            // Annuler tout timer précédent
            if (this.mapMoveTimer) {
              clearTimeout(this.mapMoveTimer);
            }
            
            // Attendre 500ms après la fin du mouvement pour filtrer
            this.mapMoveTimer = setTimeout(() => {
              console.log('Déplacement terminé, filtrage des résidences...');
              this.filterResidencesInCurrentZone();
            }, 500);
          }
        });
        
        // Log when map is clicked
        map.addListener('click', (event) => {
          console.log('Map clicked at:', event.latLng.lat(), event.latLng.lng());
        });
      }
    },
    showDetails(residence) {
      this.selectedResidence = residence;
    },
    closeResidenceDetails() {
      this.selectedResidence = null;
    },
    toggleMap() {
      this.showMap = !this.showMap;
      this.selectedResidence = null;
      this.closeResidenceDetails();
      if(this.showMap) {
        this.cartePage = 10;
      } else {
        this.cartePage = 20;
      }
      // Si on active la carte, s'assurer qu'elle se redimensionne correctement
      if (this.showMap && this.$refs.gmap && this.$refs.gmap.$mapObject) {
        setTimeout(() => {
          this.$refs.gmap.$mapObject.setZoom(this.$refs.gmap.$mapObject.getZoom());
        }, 100);
      }

      this.ListeFiltre2();
    },
    
    // Nouvelle méthode pour gérer la fin du glisser-déposer d'un marqueur
    onMarkerDragEnd(position) {
      console.log('Marker dragged to:', position.lat(), position.lng());
    },
    
    // Nouvelle méthode pour gérer les informations de localisation après un déplacement
    onLocationChanged(residence, locationInfo) {
      console.log('Location changed event received:', locationInfo);
      
      // Mettre à jour les informations de localisation de la résidence
      if (residence) {
        console.log('Updating residence:', residence.residence.id);
        
        // Créer une copie de la résidence avec les nouvelles informations de localisation
        const updatedResidence = {
          ...residence,
          locationInfo: locationInfo,
          position: {
            lat: locationInfo.position.lat(),
            lng: locationInfo.position.lng()
          }
        };
        
        // Si c'est la résidence actuellement sélectionnée, mettre à jour la sélection
        if (this.selectedResidence && this.selectedResidence.residence.id === residence.residence.id) {
          this.selectedResidence = updatedResidence;
          console.log('Updated selected residence with new location');
        }
        
        // Stocker les dernières informations de localisation pour l'affichage
        this.lastLocationInfo = locationInfo;
        console.log('Updated lastLocationInfo:', this.lastLocationInfo);
        
        // Afficher une notification avec les informations de localisation
        ElNotification({
          title: 'Nouvelle position',
          message: `Ville: ${locationInfo.city}, Quartier: ${locationInfo.neighborhood}`,
          type: 'success',
          duration: 5000
        });
         console.log(locationInfo);
        
        // Vous pouvez également envoyer ces informations à votre API si nécessaire
        this.updateResidenceLocation(residence.residence.id, locationInfo);
      } else {
        console.error('No residence provided with location change');
      }
      ElNotification({
          title: 'Nouvelle position',
          message: `Ville: ${locationInfo.city}, Quartier: ${locationInfo.neighborhood}`,
          type: 'success',
          duration: 5000
        });
    },
    
    // Méthode pour envoyer les nouvelles informations de localisation à votre API
    updateResidenceLocation(residenceId, locationInfo) {
      // Exemple d'implémentation - à adapter selon votre API
      /*
      this.$axios.post('/update_residence_location.php', {
        id: residenceId,
        lat: locationInfo.position.lat(),
        lng: locationInfo.position.lng(),
        city: locationInfo.city,
        neighborhood: locationInfo.neighborhood,
        address: locationInfo.fullAddress
      })
      .then(response => {
        console.log('Location updated in database:', response.data);
      })
      .catch(error => {
        console.error('Error updating location:', error);
      });
      */
      
      // Pour l'instant, on se contente de logger les informations
      console.log('Mise à jour de la position de la résidence ID:', residenceId, {
        lat: locationInfo.position.lat(),
        lng: locationInfo.position.lng(),
        city: locationInfo.city,
        neighborhood: locationInfo.neighborhood,
        address: locationInfo.fullAddress
      });
    },
    
    // Nouvelles méthodes pour le filtre de distance
    handleDistanceFilterChange() {
      console.log('Distance filter changed:', this.useDistanceFilter, this.distanceCenter);
      
      if (this.useDistanceFilter) {
        this.setCenterPosition();
      } else {
        this.centerPosition = null;
      }
      
      // Appliquer le filtre
      this.ListeFiltreDansRayon();
    },
    
    setCenterPosition() {
      if (this.distanceCenter === 'current') {
        this.locateMe();
      } else if (this.distanceCenter === 'city') {
        // Centre d'Abidjan
        this.centerPosition = { lat: 5.318790, lng: -4.013572 };
        this.ListeFiltreDansRayon();
      } else {
        // Quartier sélectionné
        const quartier = this.quartiers.find(q => q.id === this.distanceCenter);
        if (quartier) {
          this.centerPosition = quartier.position;
          this.ListeFiltreDansRayon();
        }
      }
    },
    
    locateMe() {
      this.locating = true;
      
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('Geolocation success:', position.coords);
            this.centerPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.locating = false;
            
            // Appliquer le filtre avec la nouvelle position
            this.ListeFiltreDansRayon();
            
            // Centrer la carte sur la position
            if (this.showMap && this.$refs.gmap && this.$refs.gmap.$mapObject) {
              this.$refs.gmap.$mapObject.setCenter(this.centerPosition);
            }
            
            ElNotification({
              title: 'Localisation',
              message: 'Votre position a été détectée avec succès',
              type: 'success'
            });
          },
          (error) => {
            console.error('Geolocation error:', error);
            this.locating = false;
            
            ElNotification({
              title: 'Erreur de localisation',
              message: 'Impossible de déterminer votre position. ' + error.message,
              type: 'error'
            });
            
            // Utiliser le centre d'Abidjan par défaut
            this.centerPosition = { lat: 5.318790, lng: -4.013572 };
            this.distanceCenter = 'city';
            this.ListeFiltreDansRayon();
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser');
        this.locating = false;
        
        ElNotification({
          title: 'Erreur de localisation',
          message: 'La géolocalisation n\'est pas prise en charge par votre navigateur',
          type: 'error'
        });
        
        // Utiliser le centre d'Abidjan par défaut
        this.centerPosition = { lat: 5.318790, lng: -4.013572 };
        this.distanceCenter = 'city';
        this.ListeFiltreDansRayon();
      }
    },
    
    // Calcul de la distance entre deux points géographiques (formule de Haversine)
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Rayon de la Terre en km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2); 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const distance = R * c; // Distance en km
      return distance;
    },
    
    deg2rad(deg) {
      return deg * (Math.PI/180);
    },
    
    // Nouvelle méthode pour filtrer les résidences dans un rayon de 40km
    ListeFiltreDansRayon() {
      if (!this.useDistanceFilter || !this.centerPosition) {
        // Si le filtre de distance n'est pas activé, utiliser le filtre normal
        this.ListeFiltre2();
        return;
      }
      
      const loading = ElLoading.service({
        lock: true,
        text: 'Recherche des résidences dans un rayon de 40km...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.selectedType,
        ville: this.biens.rville,
        parpage: this.cartePage,
        commune: this.biens.rquartier,
        piscine: this.biens.piscine,
        internet: this.biens.internet,
        balcon: this.biens.balcon,
        jaccuzi: this.biens.jaccuzi,
        parking: this.biens.parking,
        bord_mer: this.biens.bord_mer,
        bord_route: this.biens.bord_route,
        climatisation: this.biens.climatisation,
        netflix: this.biens.netflix,
        moustiquaire: this.biens.moustiquaire,
        refrigerateur: this.biens.refrigerateur,
        lave_machine: this.biens.lave_machine,
        // Ajouter les coordonnées du centre pour le filtre de distance
        center_lat: this.centerPosition.lat,
        center_lng: this.centerPosition.lng,
        distance_max: 8 // 40km
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }

      const randomId = Math.floor(Math.random() * 1000000);
      
      // Utiliser une nouvelle API pour la recherche par distance
      this.$axios.post('/web_liste_appart_distance.php?page=' + this.biens.rpage + '&id=' + randomId, postData)
        .then(response => {
          this.searchOk = true;
          
          // Si l'API de distance existe
          if (response.data && response.data.data) {
            this.datas = response.data.data;
            let data = {
              datas: response.data.data,
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage,
            };
            this.$store.commit('setDatas', data);
            
            if (response.data.data.length > 0) {
              let dons = response.data.data[0];
              this.$store.commit("setDonneesBien", dons);
            }
            
            ElNotification({
              title: 'Recherche par distance',
              message: `${response.data.total_rows} résidences trouvées dans un rayon de 8km`,
              type: 'success'
            });
          } else {
            // Fallback: filtrer côté client si l'API n'existe pas
            this.filterResidencesByDistance();
          }
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
          // Fallback: filtrer côté client en cas d'erreur
          this.filterResidencesByDistance();
        })
        .finally(() => {
          loading.close();
        });
    },
    
    // Méthode de fallback pour filtrer les résidences par distance côté client
    filterResidencesByDistance() {
      console.log('Filtrage côté client par distance');
      
      // D'abord, récupérer toutes les résidences sans filtre de distance
      const loading = ElLoading.service({
        lock: true,
        text: 'Filtrage des résidences par distance...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.selectedType,
        ville: this.biens.rville,
        parpage: 100, // Récupérer plus de résidences pour le filtrage
        commune: this.biens.rquartier,
        piscine: this.biens.piscine,
        internet: this.biens.internet,
        balcon: this.biens.balcon,
        jaccuzi: this.biens.jaccuzi,
        parking: this.biens.parking,
        bord_mer: this.biens.bord_mer,
        bord_route: this.biens.bord_route,
        climatisation: this.biens.climatisation,
        netflix: this.biens.netflix,
        moustiquaire: this.biens.moustiquaire,
        refrigerateur: this.biens.refrigerateur,
        lave_machine: this.biens.lave_machine,
      };
      
      const randomId = Math.floor(Math.random() * 1000000);
      
      this.$axios.post('/web_liste_appart.php?page=1&id=' + randomId, postData)
        .then(response => {
          if (response.data && response.data.data) {
            // Filtrer les résidences qui sont dans un rayon de 40km
            const filteredResidences = response.data.data.filter(residence => {
              if (!residence.position || !residence.position.lat || !residence.position.lng) {
                return false;
              }
              
              const distance = this.calculateDistance(
                this.centerPosition.lat,
                this.centerPosition.lng,
                residence.position.lat,
                residence.position.lng
              );
              
              // Ajouter la distance à la résidence pour l'affichage
              residence.distance = distance;
              
              return distance <= 8; // 8km
            });
            
            // Trier par distance
            filteredResidences.sort((a, b) => a.distance - b.distance);
            
            // Paginer les résultats
            const pageSize = this.cartePage;
            const totalPages = Math.ceil(filteredResidences.length / pageSize);
            const startIndex = (this.biens.rpage - 1) * pageSize;
            const paginatedResidences = filteredResidences.slice(startIndex, startIndex + pageSize);
            
            // Mettre à jour les données
            this.datas = paginatedResidences;
            let data = {
              datas: paginatedResidences,
              total_row: filteredResidences.length,
              total_pages: totalPages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage,
            };
            this.$store.commit('setDatas', data);
            
            if (paginatedResidences.length > 0) {
              let dons = paginatedResidences[0];
              this.$store.commit("setDonneesBien", dons);
            }
            
            ElNotification({
              title: 'Recherche par distance',
              message: `${filteredResidences.length} résidences trouvées dans un rayon de 8km`,
              type: 'success'
            });
          }
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
          ElNotification({
            title: 'Erreur',
            message: 'Impossible de filtrer les résidences par distance',
            type: 'error'
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    
    // Calcule les limites géographiques (min/max lat/lng) pour un rayon de 20km
    calculateBounds(centerPosition) {
      // Convertir le rayon de 8km en degrés de latitude (approximativement)
      // 1 degré de latitude = environ 111km
      const latDelta = 8 / 111;
      
      // Convertir le rayon en degrés de longitude (varie selon la latitude)
      // À l'équateur, 1 degré de longitude = environ 111km
      // Aux autres latitudes, on ajuste avec le cosinus
      const lngDelta = 20 / (111 * Math.cos(centerPosition.lat * Math.PI / 180));
      
      // Calculer les limites min/max
      const bounds = {
        minLat: centerPosition.lat - latDelta,
        maxLat: centerPosition.lat + latDelta,
        minLng: centerPosition.lng - lngDelta,
        maxLng: centerPosition.lng + lngDelta
      };
      
      console.log('Zone de filtrage calculée:', bounds);
      
      return bounds;
    },

    filterResidencesInCurrentZone() {
      // Récupérer le centre actuel de la carte
      if (!this.$refs.gmap || !this.$refs.gmap.$mapObject) {
        console.error('Map not available');
        return;
      }
      
      const mapCenter = this.$refs.gmap.$mapObject.getCenter();
      const centerPosition = {
        lat: mapCenter.lat(),
        lng: mapCenter.lng()
      };
      
      // Calculer les limites de la zone
      const bounds = this.calculateBounds(centerPosition);
      
      // Afficher une notification
      ElNotification({
        title: 'Zone de filtrage',
        message: `Filtrage dans un rayon de 20km autour de la position actuelle`,
        type: 'info',
        duration: 2000
      });
      
      // Appeler votre API avec les limites calculées
      this.fetchResidencesInZone(bounds);
    },

    // Méthode pour appeler l'API avec les limites de la zone
    fetchResidencesInZone(bounds) {
      // Annuler toute requête précédente
      this.cancelPendingRequest();
      
      const loading = ElLoading.service({
        lock: true,
        text: 'Recherche des résidences dans la zone...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      
      // Créer un token d'annulation
      const CancelToken = this.$axios.CancelToken;
      const source = CancelToken.source();
      this.currentRequest = source;
      
      // Nouvelle méthode utilisant PUT avec uniquement les limites géographiques
      this.sendGeographicBounds(bounds, source.token, loading);
    },
    
    // Nouvelle méthode pour envoyer les limites géographiques via PUT
    sendGeographicBounds(bounds, cancelToken, loading) {
      // Préparer les données avec uniquement les limites géographiques
      const postData = {
        min_lat: bounds.minLat,
        max_lat: bounds.maxLat,
        min_lng: bounds.minLng,
        max_lng: bounds.maxLng,
        parpage: this.cartePage
      };
      
      const randomId = Math.floor(Math.random() * 1000000);
      
      // Utiliser le même endpoint que ListeFiltre2 mais avec PUT
      this.$axios.put('/web_liste_appart.php?page=' + this.biens.rpage + '&id=' + randomId, postData, {
        cancelToken: cancelToken
      })
        .then(response => {
          this.currentRequest = null;
          this.searchOk = true;
          
          if (response.data && response.data.data) {
            this.datas = response.data.data;
            let data = {
              datas: response.data.data,
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage,
            };
            this.$store.commit('setDatas', data);
            let dons = response.data.data[0];
            this.$store.commit("setDonneesBien", dons);
            
            ElNotification({
              title: 'Filtrage géographique',
              message: `${response.data.total_rows} résidences trouvées dans la zone`,
              type: 'success'
            });
          } else {
            ElNotification({
              title: 'Filtrage géographique',
              message: 'Aucune résidence trouvée dans la zone',
              type: 'warning'
            });
          }
        })
        .catch(error => {
          if (this.$axios.isCancel(error)) {
            console.log('Requête annulée:', error.message);
          } else {
            console.error('Erreur de requête:', error);
            
            // En cas d'erreur avec PUT, essayer avec POST comme fallback
            this.sendGeographicBoundsWithPost(bounds, cancelToken);
          }
        })
        .finally(() => {
          if (loading) {
            loading.close();
          }
        });
    },
    
    // Méthode de fallback utilisant POST si PUT échoue
    sendGeographicBoundsWithPost(bounds, cancelToken) {
      console.log('Fallback: utilisation de POST au lieu de PUT');
      
      const postData = {
        min_lat: bounds.minLat,
        max_lat: bounds.maxLat,
        min_lng: bounds.minLng,
        max_lng: bounds.maxLng,
        parpage: this.cartePage
      };
      
      const randomId = Math.floor(Math.random() * 1000000);
      
      this.$axios.post('/web_liste_appart.php?page=' + this.biens.rpage + '&id=' + randomId, postData, {
        cancelToken: cancelToken
      })
        .then(response => {
          this.currentRequest = null;
          this.searchOk = true;
          
          if (response.data && response.data.data) {
            this.datas = response.data.data;
            let data = {
              datas: response.data.data,
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage,
            };
            this.$store.commit('setDatas', data);
            
            if (response.data.data.length > 0) {
              let dons = response.data.data[0];
              this.$store.commit("setDonneesBien", dons);
            }
          }
        })
        .catch(error => {
          if (this.$axios.isCancel(error)) {
            console.log('Requête fallback annulée:', error.message);
          } else {
            console.error('Erreur de requête fallback:', error);
            ElNotification({
              title: 'Erreur',
              message: 'Impossible de filtrer les résidences par zone géographique',
              type: 'error'
            });
          }
        });
    },
    
    cancelPendingRequest() {
      if (this.currentRequest) {
        console.log('Annulation de la requête précédente');
        this.currentRequest.cancel('Opération annulée par une nouvelle requête');
        this.currentRequest = null;
      }
    },
    async getResponse() {
      if (!this.userInput) {
        alert("Veuillez saisir une question.");
        return;
      }
      try {
        this.response = await askOpenAI(this.userInput);
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    async activerCompte() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        const chargement = ElLoading.service({
          lock: true,
          text: 'Activation du compte...',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        try {
          const reponse = await this.$api.postActivation({ token: code });

          if (reponse.data.valide === 1) {
            ElNotification({
              title: 'Succès',
              message: reponse.data.mes || 'Compte activé avec succès',
              type: 'success',
            });
            this.$router.push('/dashboard'); // Redirect to dashboard after successful activation
          } else {
            ElNotification({
              title: 'Erreur',
              message: reponse.data.message || 'Erreur lors de l\'activation du compte',
              type: 'error',
            });
            this.$router.replace('/');
          }
        } catch (erreur) {
          console.error('Erreur de requête:', erreur);
          ElNotification({
            title: 'Erreur',
            message: 'Une erreur est survenue lors de l\'activation du compte',
            type: 'error',
          });
        } finally {
          chargement.close();
        }
      }
    },
    ListeFiltre2() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.selectedType,
        ville: this.biens.rville,
        parpage: this.cartePage,
        commune: this.biens.rquartier,
        piscine: this.biens.piscine,
        internet: this.biens.internet,
        balcon: this.biens.balcon,
        jaccuzi: this.biens.jaccuzi,
        parking: this.biens.parking,
        bord_mer: this.biens.bord_mer,
        bord_route: this.biens.bord_route,
        climatisation: this.biens.climatisation,
        netflix: this.biens.netflix,
        moustiquaire: this.biens.moustiquaire,
        refrigerateur: this.biens.refrigerateur,
        lave_machine: this.biens.lave_machine,
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }

      const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page=' + this.biens.rpage + '&id=' + randomId, postData)
        .then(response => {
          this.searchOk = true;
          this.datas = response.data.data;
          let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
            rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage,
          };
          this.$store.commit('setDatas', data);
          let dons = response.data.data[0];
          this.$store.commit("setDonneesBien", dons);
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        });
      loading.close();
    },

    handlePageChange(page) {
      this.$store.commit('setBiensPage', page);
      this.biens.rpage = page;
      
      // Utiliser la méthode de filtre appropriée
      if (this.useDistanceFilter && this.centerPosition) {
        this.ListeFiltreDansRayon();
      } else {
        this.ListeFiltre2();
      }
      
      // Scroll to top of the scrollable list instead of the window
      if (this.showMap) {
        const scrollableList = document.querySelector('.scrollable-list');
        if (scrollableList) {
          scrollableList.scrollTop = 0;
        }
      } else {
        this.scrollToTop();
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    this.ListeFiltre2();
    this.activerCompte();
    
    // Add a timeout to ensure the map is fully loaded
    setTimeout(() => {
      console.log('Checking map status after timeout');
      if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
        console.log('Map object is available');
      } else {
        console.error('Map object is not available');
      }
    }, 2000);
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.fixebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
}

.filter-container {
  margin-top: 40px;
}

.distance-filter {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.distance-center {
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
  gap: 10px;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
  flex: 1;
  overflow: hidden;
  margin-top: 10px; /* Add space for the fixed navbar */
}

.split-view {
  flex-direction: row;
  height: calc(100vh - 160px); /* Adjust based on your navbar and filter height */
}

.list-container {
  width: 100%;
  transition: width 0.3s ease;
  height: 100%;
}

.list-reduced {
  width: 38%; /* 2/5 de la largeur */
}

.scrollable-list {
  height: 100%;
  overflow-y: auto;
  padding: 35px 10px;
  
  /* Masquer la scrollbar dans les navigateurs basés sur Webkit (Chrome, Safari) */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.scrollable-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.map-container {
  display: none;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
}

.map-visible {
  display: block;
  width: 60%; /* 3/5 de la largeur */
}

.map-header {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  min-height: 43px;
}

.location-info-banner {
  background-color: #d19100;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 0.9rem;
}

.map-button-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: bottom 0.5s ease;
}

/* Nouvelle classe pour masquer le bouton */
.map-button-hidden {
  bottom: -300px !important;
}

.map-button {
  padding: 10px 20px;
  font-weight: bold;
}

.pagination {
  width: 90%;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-bottom: 20px;
}

/* Fix for GMapMap to take full height of its container */
.map-container .map-header + div {
  height: calc(100% - 43px) !important;
}

@media (max-width: 768px) {
  .split-view {
    flex-direction: column;
    height: auto;
  }
  
  .list-reduced {
    width: 100%;
    height: 50vh;
  }
  
  .map-visible {
    width: 100%;
    height: 50vh;
  }
  
  .scrollable-list {
    max-height: 50vh;
  }
  
  .distance-center {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>