<template>
  <div>
    <div v-if="login" class="login-overlay" @click="closeLogin">
      <div class="login-card" @click.stop>
        <Login v-if="loginPage" />
        <Inscription v-else />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import Login from '@/components/dashboard/dashboard_login/LoginIndex.vue'
import Inscription from '@/components/dashboard/dashboard_login/InscriptionIndex.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    Login,
    Inscription
  },
  props: {
    web: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      title: 'SaRezide - Réservation de résidence',
      description: 'Plateforme de réservation de résidence meublée en côte d\'Ivoire',
      image: 'http://res.cloudinary.com/diwfzmygz/image/upload/v1739373828/202511272391945.png',
      url: 'https://sarezide.ci',
      userCode: null,
      lastTrackedPath: null,
      trackingDelay: 500, // délai en millisecondes
      trackVisitTimeout: null
    }
  },
  computed: {
    login() {
      return this.$store.state.loginActive
    },
    loginPage() {
      return this.$store.state.LoginPage
    },
    userEmail() {
      return this.$store.state.user?.email || null
    }
  },
  methods: {
    closeLogin() {
      this.$store.commit('setLogin', false)
    },
    updateHead() {
      useHead({
        title: this.title,
        htmlAttrs: {
          lang: 'fr',
        },
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'description', content: this.description },
          { property: 'og:type', content: 'website' },
          { property: 'og:title', content: this.title },
          { property: 'og:description', content: this.description },
          { property: 'og:image', content: this.image },
          { property: 'og:url', content: this.url },
        ]
      })
    },
    generateUserCode() {
      const now = new Date()
      return `user_${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`
    },
    getUserCode() {
      let userCode = localStorage.getItem('userCode')
      if (!userCode) {
        userCode = this.generateUserCode()
        localStorage.setItem('userCode', userCode)
      }
      return userCode
    },
    async trackVisit() {
      clearTimeout(this.trackVisitTimeout);
      this.trackVisitTimeout = setTimeout(async () => {
        const currentPath = this.$route.path;
        
        // Ignorer la requête si c'est la racine "/" et qu'une autre navigation se produit rapidement
        if (this.lastTrackedPath === '/' && currentPath !== '/') {
          console.log('Ignoring root path tracking as navigation occurred');
          return;
        }

        try {
          const userCode = this.userEmail || this.getUserCode()
          const userIp = await this.getUserIp()
          const userCountry = await this.getUserCountry(userIp)
          const userCity = await this.getUserCity(userIp)

          const response = await axios.post('https://korezide.com/appi_web/info_utilisateurs.php', {
            userPath: currentPath,
            userCountry,
            userCity,
            userIp,
            userCode
          })

          console.log('Visit tracked:', response.data)
          this.lastTrackedPath = currentPath;
        } catch (error) {
          console.error('Error tracking visit:', error)
        }
      }, this.trackingDelay);
    },
    async getUserIp() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json')
        return response.data.ip
      } catch (error) {
        console.error('Error fetching IP:', error)
        return 'Unknown'
      }
    },
    async getUserCountry(ip) {
      try {
        const response = await axios.get(`https://ipapi.co/${ip}/country_name/`)
        return response.data
      } catch (error) {
        console.error('Error fetching country:', error)
        return 'Unknown'
      }
    },
    async getUserCity(ip) {
      try {
        const response = await axios.get(`https://ipapi.co/${ip}/city/`)
        return response.data
      } catch (error) {
        console.error('Error fetching city:', error)
        return 'Unknown'
      }
    }
  },
  mounted() {
    this.updateHead()
    this.trackVisit()
  },
  watch: {
    title() {
      this.updateHead()
    },
    description() {
      this.updateHead()
    },
    image() {
      this.updateHead()
    },
    url() {
      this.updateHead()
    },
    $route() {
      // Réinitialiser le lastTrackedPath si la navigation n'est pas immédiate
      if (this.trackVisitTimeout) {
        clearTimeout(this.trackVisitTimeout);
      }
      this.trackVisit()
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 19px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.login-overlay {
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
}

.login-card {
  background-color: white;
  width: 100%;
  z-index: 1001;
}

.page-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>