<template>
    <div>
      <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
        <nav class="navbar">
          <div style="width: 1625px; margin: 0 auto">
            <div v-if="lala === 0">
              <el-row :gutter="20">
                <el-col :span="4" v-for="el in 30" :key="el">
                  <el-skeleton style="width: 270px" :loading="loading" animated>
                    <template #template>
                      <el-skeleton-item variant="image" style="width: 270px; height: 270px" />
                      <div style="padding: 14px">
                        <el-skeleton-item variant="p" style="width: 70%" /><br>
                        <el-skeleton-item variant="p" style="width: 30%" /><br>
                        
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-items: space-between;"
                        >
                        </div>
                      </div>
                    </template>
                  </el-skeleton>
                </el-col>
              </el-row>
            </div>
            <div v-else style="max-width: 1625px; margin: 0 auto; margin-top: -70px">

              
              <br/>
              <div style="width: 100%; display: ruby">
                
                <div style="width: 325px; padding: 8px" v-for="item in msg" :key="item.id_item">
                  <div class="image-item" style="width: 100%" @click="changeBiens(item)">
                      <el-carousel height="270px" :autoplay="false">
                        <template v-for="image in item.images" :key="image.id_image">
                          <el-carousel-item v-if="image.lien.length > 25">
                              <img :src="image.lien" alt="Property Image 1">
                          </el-carousel-item>
                        </template>
                          <!-- <div v-if="item.residence.commune_resid == 'Cocody'" class="tagg is-white" style="font-weight: bold">Confiance</div> -->
                          <div class="tag is-white" style="font-weight: bold">{{item.residence.type_resid}}</div>
                      </el-carousel>
                  </div>
                  <div class="texte-zone">
                    <p style="font-size: 18px; font-weight: 500;">{{formatLibelle(item.residence.libelle_resid)}}</p>
                    <p>{{item.residence.ville_resid}}, {{item.residence.commune_resid}}</p>
                    <p style="font-size: 17px; font-weight: 500;">{{formatMoney(item.residence.prix_nuit)}} <span style="font-weight: 300">/ nuit</span></p>
                  </div>
                </div>
              </div>
             
            </div>
            
          </div>
        </nav>
      </div>
    </div> 
  </template>  
  
  <script>
  import { isMobile } from 'mobile-device-detect';
  import { isTablet } from 'mobile-device-detect';
  import { isBrowser } from 'mobile-device-detect';
  import { mapState, mapActions } from 'vuex';
  export default {
    name: 'AccueilNavbar',
    props: {
        msg: Array
    },
    computed: {
        ...mapState({
        biens: state => state.biens.datas,
        totalInfo: state => state.biens
      }),
      displayedProperties() {
        return this.msg || this.biens;
      }
    },
    data() {
      return {
        lala: 0,
        loading: true,
        ismobile: isMobile,
        istablet: isTablet,
        isbrowser: isBrowser,
        width: window.innerWidth,
        height: window.innerHeight,

      };
    },
    methods: {
      ...mapActions(['listeFiltre2']),
    // ... (keep existing methods)

    async updateList() {
        this.loading = true;
        try {
          await this.listeFiltre2({
            prix_min: this.value1[0] * 5000,
            prix_max: this.value1[1] * 5000,
            type: this.selectedType,
            ville: this.$store.state.biens.rville,
            parpage: 20,
            commune: this.$store.state.biens.rquartier,
            // ... (include other filter parameters)
          });
        } catch (error) {
          console.error('Error updating list:', error);
        } finally {
          this.loading = false;
          this.lala = 1;
        }
      },
      skeleton() {
        setTimeout(() => {
          this.lala = 1;
        }, 2000);
      },

      updateDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      },

      changeBiens(bien) {
        console.log(bien.residence.id_resid);
        this.$router.push({ name: 'detail', params: { param: bien.residence.id_resid } });
      // Déclencher la mutation pour stocker les détails du bien
      //this.$store.commit("setDonneesBien", bien);
      // Naviguer vers la page de détails en passant l'identifiant du bien en paramètre
      },

      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },

      formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },

      formatLibelle(libelle) {
        if (libelle.length > 26) {
          return libelle.substring(0, 26) + '...';
        } else {
          return libelle;
        }
      },

    },
    mounted() {
      this.skeleton();
      window.addEventListener('resize', this.updateDimensions);
    },
    watch: {
    biens: {
      handler(newBiens) {
        console.log('Biens updated:', newBiens);
        // Force a re-render if needed
        this.$forceUpdate();
        this.scrollToTop();
      },
      deep: true
    },
  }
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap');
  /* Styles spécifiques au composant */
  .navbar {
    top: 0;
    width: 100%;
    padding: 10px;
    max-width: 1880px;
    margin: 0 auto;
    z-index: 1000;
    background: white;
  }
  
  .image-item2 {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 200px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }

  .image-item {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 270px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }
  
  .image-item img {
    width: 100%; /* Assurez-vous que l'image occupe toute la largeur disponible */
    height: 100%; /* Ajuste automatiquement la hauteur pour maintenir le ratio d'aspect */
    object-fit: cover; /* Zoomer sur l'image tout en conservant le ratio d'aspect */
  }
  
  .tag {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #74716ba1;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }

  .tagg {
    position: absolute;
    top: 20px;
    left: 10px;
    background-color: white;
    color: black;
    padding: 5px 10px;
    font-weight: normal;
    font-size: 15px;
    border-radius: 4px;
  }
  
  .texte-zone {
    font-family: "Roboto Flex", serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
    font-size: 14px;  
    text-align: left; /* Alignement du texte à gauche */
    padding-top: 10px;
    padding-bottom: 20px; /* Espacement interne */
  }
  
  .texte-zone p {
    margin: 5px 0;
    line-height: 1.2; /* Espacement entre les lignes de texte */
  }

  .certification-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: green; /* Couleur de fond pour le tag */
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }

  .el-carousel__button {
    width: 5px;
  }
  </style>
  