<template>
  <div>
    <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
      <nav class="navbar">
        <div style="width: 100%;">
          <div v-if="lala === 0">
            <el-row :gutter="24">
              <el-col :span="24" v-for="el in 5" :key="el">
                <el-skeleton style="width: 100%" :loading="loading" animated>
                  <template #template>
                    <el-skeleton-item variant="image" style="width: 100%; height: 240px" />
                    <div style="padding: 14px">
                      <el-skeleton-item variant="p" style="width: 80%" />
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-items: space-between;"
                      >
                        <el-skeleton-item variant="text" style="margin-right: 16px" />
                        <el-skeleton-item variant="text" style="width: 30%" />
                      </div>
                    </div>
                  </template>
                </el-skeleton>
              </el-col>
            </el-row>
          </div>
          <div v-else style="width: 100%; display: ruby">
            <div style="display: ruby">
              <div style="width: 94%; margin-left: 3%; margin-bottom: 10px;" v-for="item in msg" :key="item.id_item">
                <div class="image-item" style="width: 100%" @click="changeBiens(item.residence.id_resid)">
                  <!-- Utilisation de Swiper -->
                  <carousel :items-to-show="1">
                    <slide v-for="item in filteredImages(item.images)" :key="item.id_image" style="height: 300px">
                      <img :src="item.lien" class="carousel-image" @click="isOpen3 = true">
                    </slide>
                
                    <template #addons>
                      <navigation />
                      <pagination />
                    </template>
                  </carousel>
                  <div class="swiper-pagination"></div> <!-- Pagination -->
                  <div class="tag">{{item.residence.type_resid}}</div>
                  <div v-if="item.residence.duree_reduction.length >= 1" class="discount-badge">
                    <span class="discount-icon">%</span>
                    Réduction disponible
                  </div>
                </div>
                <div class="texte-zone">
                  <div class="top-row">
                    <p style="color: gray">{{item.residence.libelle_resid}}</p>
                    <div class="price-tag">
                      <span class="price">{{ formatMoney(item.residence.prix_nuit) }}</span>
                      <span class="per-night">/ nuit</span>
                    </div>
                  </div>
                  <p><b>{{item.residence.ville_resid}} | {{item.residence.commune_resid}}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name: 'AccueilNavbar',
  props: {
    msg: Array
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    biens() {
      return this.$store.state.donneesBiens;
    },
  },
  data() {
    return {
      lala: 0,
      loading: true,
      width: window.innerWidth,
      height: window.innerHeight,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      },
    };
  },
  methods: {
    skeleton() {
      setTimeout(() => {
        this.lala = 1;
      }, 2000);
    },

    formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },

    updateDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },

    filteredImages(images) {
      return images.filter(image => image.lien.length > 20);
    },

    changeBiens(bien) {
        console.log(bien);
        this.$router.push({ name: 'detail', params: { param: bien } });
      // Déclencher la mutation pour stocker les détails du bien
      //this.$store.commit("setDonneesBien", bien);
      // Naviguer vers la page de détails en passant l'identifiant du bien en paramètre
    },
  },
  mounted() {
    this.skeleton();
    window.addEventListener('resize', this.updateDimensions);
  },
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.navbar {
  top: 0;
  width: 100%;
  padding: 10px;
  max-width: 1880px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  font-size: 15px;
  color: black;
}

.discount-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: linear-gradient(135deg, #fff, #ffffff);
  color: black;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.discount-icon {
  background: white;
  color: #ff6600;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.image-item2 {
  position: relative; /* Position relative pour le positionnement absolu du tag */
  text-align: center; /* Centrer les images horizontalement */
  height: 200px; /* Hauteur fixe pour toutes les images */
  overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
  border-radius: 15px;
}

.image-item {
  position: relative; /* Position relative pour le positionnement absolu du tag */
  text-align: center; /* Centrer les images horizontalement */
  height: 300px; /* Hauteur fixe pour toutes les images */
  overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
  border-radius: 15px;
}

.image-item img {
  width: 100%; /* Assurez-vous que l'image occupe toute la largeur disponible */
  height: 300px; /* Ajuste automatiquement la hauteur pour maintenir le ratio d'aspect */
  object-fit: cover; /* Zoomer sur l'image tout en conservant le ratio d'aspect */
}

.tag {
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  z-index: 10;
  backdrop-filter: blur(4px); /* Assurez-vous que le tag est au-dessus de l'image */
}
.texte-zone {
  text-align: left; /* Alignement du texte à gauche */
  padding-top: 10px;
  padding-bottom: 20px; /* Espacement interne */
}

.texte-zone p {
  margin: 5px 0;
  line-height: 1.2; /* Espacement entre les lignes de texte */
}

.texte-zone .top-row {
  display: flex;
  justify-content: space-between; /* Espace entre la ville/commune et le tarif */
  align-items: center;
}

.texte-zone .tarif {
  margin-left: auto; /* Pousse le tarif à droite */
}

.certification-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: green; /* Couleur de fond pour le tag */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.el-carousel__button {
  width: 5px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.price-tag {
  display: block;
  flex-direction: column;
  align-items: flex-end;
}

.price {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
</style>

