<template>
  <div>
    <div class="summary-page">
      <div class="page-header">
        <p class="title-detail" style="font-family: 'Montserrat', sans-serif; font-size: 20px"></p>
        <button class="button is-rounded is-small close-button" @click="closeLogin">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>

      <!-- Bloc 2 : Séjour -->
      <div class="block" style="margin-top: -1px">
        <h3 class="title is-5" style="text-align: center; font-size: 30px">Connexion</h3>
        <p style="text-align: center;margin-top: -10px">J'ai pas de compte, <el-link @click="Inscription()" type="primary" style="font-size: 19px">créez-en un</el-link></p>
        <div style="margin-top:30px">

          <div class="field">
            <div class="control is-large has-icons-left has-icons-right">
              <input
                v-model="login.email"
                class="input is-medium"
                type="text"
                placeholder="Email"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right">
                <i class="fas fa-check"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <p class="control is-large has-icons-left">
              <input v-model="login.password" class="input is-medium" type="password" placeholder="Mot de passe" />
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
          </div>

        </div>
      </div>

      <div style="width: 100%">
        <button style="width: 100%" class="button is-primary is-fullwidth" @click="submitLogin">Connexion</button>
      </div>

      <el-divider />

      <!-- Bouton de connexion Google -->
      <GoogleLogin
        style="margin-bottom: 70px;"
        :callback="handleGoogleLogin"
        :clientId="'658508526723-2sdfq97i9qsulfk1e6va233kimseokn4.apps.googleusercontent.com'"
        :scope="'email profile'"
        :prompt="'consent'"
        class="google-login-button"
      >
        <div v-if="loading">
          <ActivityIndicator size="small" color="orange" />
          <span class="ml-3">Connexion en cours...</span>
        </div>
        <div v-else class="button-content">
          <img :src="logo.google" alt="Google logo" class="button-icon" />
          <span class="button-text">Continuer avec Google</span>
        </div>
      </GoogleLogin>

    </div>
  </div>
</template>

<script>
import { ElLoading, ElNotification } from 'element-plus';
import axios from 'axios';

export default {
  name: 'SummaryPage',
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      loading: false,
      logo: {
        facebook: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
        google: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png', // Remplacez par votre logo Google
      },
    };
  },
  methods: {
    async submitLogin() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading...',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      try {
        // Envoie des données de connexion au serveur
        const response = await this.$api.postLogin({ login: this.login.email, password: this.login.password });

        if (response.data.status === 'success') {
          // Enregistre les données utilisateur dans une variable ou état approprié
          this.userData = response.data.data;

          ElNotification({
            title: 'Succès',
            message: 'Connexion réussie',
            type: 'success',
          });

          this.$store.commit('ConnexionAgent', this.userData);
          this.$router.push({ path: '/dashboard_mobile' });
          this.closeLogin();
        } else {
          ElNotification({
            title: 'Erreur Connexion',
            message: response.data.message || 'Identifiant ou mot de passe invalide. Veuillez réessayer.',
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Erreur de requête:', error);
        ElNotification({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la connexion. Veuillez réessayer.',
          type: 'error',
        });
      } finally {
        loading.close();
      }
    },
    async handleGoogleLogin(response) {
      this.loading = true;

      try {
        // Vérifiez que la réponse contient un code
        if (!response || !response.code) {
          throw new Error('Code d\'autorisation manquant dans la réponse Google');
        }

        console.log('Réponse Google:', response);
        const code = response.code;
        console.log('Code Google:', code);

        // Créez un objet avec les paramètres requis
        const params = {
          code: code,
          client_id: '658508526723-2sdfq97i9qsulfk1e6va233kimseokn4.apps.googleusercontent.com',
          client_secret: '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',
          redirect_uri: 'https://sarezide.ci',
          grant_type: 'authorization_code',
          scope: 'https://www.googleapis.com/auth/userinfo.profile',
        };

        // Convertissez l'objet en chaîne de requête
        const queryString = Object.keys(params)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

        // Envoyez la requête avec le bon Content-Type
        const tokenResponse = await axios.post(
          'https://oauth2.googleapis.com/token', 
          queryString,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        console.log('Réponse du token:', tokenResponse.data);
        const accessToken = tokenResponse.data.access_token;
        console.log('Token d\'accès:', accessToken);

        // Récupérez les informations du profil utilisateur
        const userProfile = await this.getUserProfile(accessToken);
        userProfile.network = 'Google';

        // Affichez les informations du profil utilisateur dans la console
        console.log('Profil utilisateur Google:', userProfile);

        // Envoyez les informations du profil utilisateur à votre backend
        const backendResponse = await this.$api.postInscriptionReseau(userProfile);

        if (backendResponse.data.status === 'success') {
          this.closeLogin();
          this.$store.commit('ConnexionAgent', backendResponse.data.data);
          this.$router.push({ path: '/dashboard_mobile' });

          ElNotification({
            title: 'Succès',
            message: 'Connexion réussie via Google.',
            type: 'success',
          });
        } else {
          ElNotification({
            title: 'Erreur',
            message: 'Impossible de se connecter via Google.',
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Erreur lors de la connexion Google:', error);
        // Afficher plus de détails sur l'erreur
        if (error.response) {
          console.error('Détails de l\'erreur:', error.response.data);
        }
        ElNotification({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la connexion Google.',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    async getUserProfile(accessToken) {
      try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Retournez les informations du profil utilisateur
        return userInfoResponse.data;
      } catch (error) {
        console.error('Erreur lors de la récupération du profil utilisateur:', error);
        return null;
      }
    },
    async handleFacebookLogin() {
        this.loading = true;
  
        try {
          // Initialise la connexion Facebook
          const response = await FB.login((response) => {
            if (response.authResponse) {
              // L'utilisateur est connecté
              const accessToken = response.authResponse.accessToken;
  
              // Affichez le token d'accès dans la console
              console.log('Token d\'accès Facebook:', accessToken);
  
              // Récupérez les informations du profil utilisateur
              this.getFacebookUserProfile(accessToken);
            } else {
              // L'utilisateur a annulé la connexion
              console.log('Connexion Facebook annulée.');
              ElNotification({
                title: 'Erreur',
                message: 'Connexion Facebook annulée.',
                type: 'error',
              });
            }
          }, { scope: 'email,public_profile' });
          console.log(response) // Demandez les permissions nécessaires
        } catch (error) {
          console.error('Erreur lors de la connexion Facebook:', error);
          ElNotification({
            title: 'Erreur',
            message: 'Une erreur est survenue lors de la connexion Facebook.',
            type: 'error',
          });
        } finally {
          this.loading = false;
        }
      },
      async getFacebookUserProfile(accessToken) {
        try {
          // Récupérez les informations du profil utilisateur
          const userProfile = await axios.get(`https://graph.facebook.com/v18.0/me?fields=id,name,email,picture&access_token=${accessToken}`);
  
          // Affichez les informations du profil utilisateur dans la console
          console.log('Profil utilisateur Facebook:', userProfile.data);
  
          // Envoyez les informations du profil utilisateur à votre backend
          const backendResponse = await axios.post('/facebook-login', {
            id: userProfile.data.id,
            email: userProfile.data.email,
            name: userProfile.data.name,
            picture: userProfile.data.picture.data.url,
          });
  
          if (backendResponse.data.status === 'success') {
            const { token: authToken, user } = backendResponse.data;
            localStorage.setItem('token', authToken);
  
            this.$store.commit('setUser', user);
            this.$store.commit('setIsLogged', true);
            this.$router.push('/dons');
  
            ElNotification({
              title: 'Succès',
              message: 'Connexion réussie via Facebook.',
              type: 'success',
            });
          } else {
            ElNotification({
              title: 'Erreur',
              message: 'Impossible de se connecter via Facebook.',
              type: 'error',
            });
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du profil utilisateur:', error);
          ElNotification({
            title: 'Erreur',
            message: 'Une erreur est survenue lors de la récupération du profil utilisateur.',
            type: 'error',
          });
        }
      },
    closeLogin() {
      let statut = false;
      this.$store.commit('setLogin', statut);
    },
    Inscription() {
      let statut = false;
      this.$store.commit('setLoginPage', statut);
    },
  },
};
</script>
  
<style scoped>
.summary-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Assure que la page prend toute la hauteur de l'écran */
  position: relative; /* Pour permettre au bouton d'être positionné en absolu */
}

.page-header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px; /* Ajuste l'espacement si nécessaire */
}

.block {
  margin-top: 100px; /* Ajuste la position du contenu */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px; /* Limite la largeur du contenu si nécessaire */
}

.divider {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
}

.field {
  margin-bottom: 1rem;
  width: 100%;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrage horizontal */
  width: 100%;
  padding-top: 8px;
  height: 50px;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.google-login-button:hover {
  background-color: #f1f1f1;
}

.google-login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.facebook-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.facebook-login-button:hover {
  background-color: #f1f1f1;
}

.facebook-login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrage horizontal */
  width: 100%; /* Prend toute la largeur du bouton */
}
.button-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.button-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 15px;
}
</style>