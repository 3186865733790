<template>
  <div ref="overlay" class="custom-overlay">
    <div class="custom-marker">
      {{ price }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      marker: null,
    };
  },
  mounted() {
    this.initializeMarker();
  },
  beforeUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  },
  methods: {
    initializeMarker() {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        console.warn('Google Maps API is not loaded. Retrying in 1 second.');
        setTimeout(() => this.initializeMarker(), 1000);
        return;
      }

      this.createMarker();
    },
    createMarker() {
      const CustomMarker = function (latlng, map, args) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(map);
      }

      CustomMarker.prototype = new google.maps.OverlayView();

      CustomMarker.prototype.draw = function() {
        const self = this;
        let div = this.div;

        if (!div) {
          div = this.div = document.createElement('div');
          div.className = 'custom-marker';
          div.style.position = 'absolute';
          div.innerHTML = this.args.content;

          google.maps.event.addDomListener(div, "click", function(event) {
            google.maps.event.trigger(self, "click");
            console.log(event);
          });

          const panes = this.getPanes();
          panes.overlayImage.appendChild(div);
        }

        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
          div.style.left = (point.x - div.offsetWidth / 2) + 'px';
          div.style.top = (point.y - div.offsetHeight) + 'px';
        }
      };

      CustomMarker.prototype.remove = function() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      };

      CustomMarker.prototype.getPosition = function() {
        return this.latlng;
      };

      const content = `<div class="marker-content">${this.price}</div>`;
      
      this.marker = new CustomMarker(
        new google.maps.LatLng(this.position.lat, this.position.lng),
        this.map,
        { content: content }
      );

      google.maps.event.addListener(this.marker, 'click', () => {
        this.$emit('click');
      });
    },
  },
};
</script>

<style>
.custom-marker {
  background-color: #d19100;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 2px 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.marker-content {
  white-space: nowrap;
}

.vue-map-hidden {
display: none !important;
}
</style>



.map-button-container {
position: fixed;
bottom: 20px;
left: 50%;
transform: translateX(-50%);
z-index: 1000;
}