<template>
  <div class="accueil_mobile_lbottom_bar">
    <div class="mobile-nav">
      <div class="container-mobile mb-2">
        <div class="mobile-group">
          <router-link
            :to="{ name: 'home', force: true }"
            class="mobile-widget mobile-active"
          >
            <i class="fa-solid fa-magnifying-glass icon"></i>
            <span>Recherche</span>
          </router-link>
          <span @click="showReservationDialog = true" class="mobile-widget">
            <i class="fa-solid fa-suitcase icon"></i>
            <span>Réservation</span>
          </span>
          <router-link
            to="#"
            @click.prevent="handleClick"
            class="mobile-widget plus-btn"
          >
            <i class="fa-solid fa-circle-plus fa-plus icon"></i>
            <span>Ajouter</span>
          </router-link>
          <span @click="showPreferencesDialog = true" class="mobile-widget">
            <i class="fa-solid fa-globe icon"></i>
            <span>Préférence</span>
          </span>
          <router-link v-if="users.nom.length >= 2"
            :to="{ name: 'dashboard_mobile', force: true }"
            class="mobile-widget"
          >
            <svg
              class="svg"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <circle
                  cx="12"
                  cy="9"
                  r="3"
                  stroke="#050a30"
                  stroke-width="2.5"
                ></circle>
                <path
                  d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
                  stroke="#050a30"
                  stroke-width="2.5"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                  stroke="#050a30"
                  stroke-width="2.5"
                  stroke-linecap="round"
                ></path>
              </g>
            </svg>
            <span style="text-transform: capitalize !important">{{users.nom}}</span>
          </router-link>
          <span v-else
           @click="loginActive()"
            class="mobile-widget"
          >
            <svg
              class="svg"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <circle
                  cx="12"
                  cy="9"
                  r="3"
                  stroke="#050a30"
                  stroke-width="2.5"
                ></circle>
                <path
                  d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
                  stroke="#050a30"
                  stroke-width="2.5"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                  stroke="#050a30"
                  stroke-width="2.5"
                  stroke-linecap="round"
                ></path>
              </g>
            </svg>
            <span style="text-transform: capitalize !important">Login</span>
          </span>
        </div>
      </div>
    </div>

    <!-- Preferences Dialog -->
    <div v-if="showPreferencesDialog" class="dialog-overlay" @click.self="showPreferencesDialog = false">
      <div class="dialog">
        <h2>Préférences</h2>
        <select v-model="selectedLanguage">
          <option value="fr">Français</option>
        </select>
        <button @click="showPreferencesDialog = false">Fermer</button>
      </div>
    </div>

    <!-- Reservation Dialog -->
    <div v-if="showReservationDialog" class="dialog-overlay" @click.self="showReservationDialog = false">
      <div class="dialog">
        <h2>Réservation</h2>
        <input 
          v-model="reservationCode" 
          type="text" 
          placeholder="Entrez votre code de réservation"
        >
        <button @click="showReservationDialog = false">Fermer</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus';

export default {
  name: 'BottomBar',
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  data() {
    return {
      merci: '',
      ChoixDevise: false,
      showPreferencesDialog: false,
      showReservationDialog: false,
      selectedLanguage: 'fr',
      reservationCode: '',
    };
  },
  methods: {
    handleClick() {
      console.log('test');
      if(this.users.agent == 'OK' && this.users.nom.length > 0) {
        this.$router.push({ name: 'newform_mobile', force: true });
      }
      if(this.users.agent === 'KO' && this.users.nom.length > 0) {
        this.popup();
      }
      if(this.users.agent === 'OK' && this.users.nom.length < 1) {
        this.loginActive();
      }
      if(this.users.agent === 'KO' && this.users.nom.length < 1) {
        this.loginActive();
      }
    },

    loginActive() {
      let statut = true;
      let autre = true;
      this.$store.commit('setLogin', statut);
      this.$store.commit('setLoginPage', autre);
    },

    popup() {
      ElMessageBox.confirm(
        `
        <div class="agent-confirmation">
          <p>En tant qu'agent :</p>
          <ul>
            <li>Ajouter et gérer vos résidences sur notre plateforme</li>
            <li>Recevez des réservations directement via notre site</li>
            <li>Bénéficier de notre visibilité et de notre réseau</li>
          </ul><br>
          <p class="commission"><strong>Commission :</strong> 10% sur chaque réservation effectuée via notre plateforme</p>
        </div>
        `,
        'Souhaitez vous devenir agent ?',
        {
          confirmButtonText: 'Oui je l\'accepte',
          cancelButtonText: 'Non merci',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          const response = this.$api.postAgent({ email: this.users.email});
          console.log(response);
          this.$store.commit('StatutAgent', 'OK');
          ElMessage({
            type: 'success',
            message: 'Vous êtes maintenant Agent, vérifiez votre e-mail.',
          });
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Espace réservé pour les agents',
          });
        });
    }
  },
  mounted() {},
};
</script>

<style scoped>
.accueil_mobile_lbottom_bar {
  background-color: #fff;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1001
}

.mobile-nav {
  display: flex;
  justify-content: center;
}

.mobile-log {
  margin-top: -200px;
  background-color: white
}

.container-mobile {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mobile-group {
  padding: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobile-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #666;
  font-size: 12px
}

.mobile-active {
  color: #3273dc;
}

.icon {
  font-size: 1.2rem;
}

.svg {
  width: 24px;
  height: 24px;
}

/* New styles for dialogs */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  /* Ajout d'un effet de transition pour une fermeture en douceur */
  transition: all 0.3s ease-in-out;
}

.dialog h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.dialog select,
.dialog input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
}

.dialog button {
  background-color: #3273dc;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}
</style>

