<template>
    <div class="filter-container">
      <nav class="navbar">
        <div class="content-wrapper">
          <p v-if="$isMobile()">Services : <b>{{totalInfo.total_row}}</b> élement(s)</p>
          <el-divider v-if="$isMobile()"/>
          <div class="button-container"  v-if="$isMobile()">
              <button 
                v-for="item in filtre_list" 
                :key="item.libelle"
                class="filter-button-mobile"
                :class="{ active: item.active }"
                @click="toggleActive(item)"
              >
                <i :class="item.icon" class="filter-icon"></i>
                <span class="filter-text">{{ item.libelle }}</span>
              </button>
          </div>
  
          <div class="button-container"  v-else>
              <button 
                v-for="item in filtre_list" 
                :key="item.libelle"
                class="filter-button"
                :class="{ active: item.active }"
                @click="toggleActive(item)"
              >
                <i :class="item.icon" class="filter-icon"></i>
                <span class="filter-text">{{ item.libelle }}</span>
              </button>
          </div>
          <p class="info-text" v-if="!$isMobile()">
            <span style="font-weight: 400">
              <span v-if="totalInfo.rville !='0'">{{totalInfo.rville}}</span>
              <span v-else>Total</span>
              <span v-if="totalInfo.rquartier !='0'"> - {{totalInfo.rquartier}}</span> : {{totalInfo.total_row}}
            </span> 
            résidences / Page <b>{{totalInfo.rpage}} - {{totalInfo.total_pages}}</b>
          </p>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  import { ElLoading } from 'element-plus'
  import axios from 'axios'
  export default {
    name: 'AccueilFilter',
    computed: {
      totalInfo() {
        return this.$store.state.biens;
      },
    },
    data() {
      return {
        filtre_list: [
          { libelle: 'Piscine', icon: 'fa-solid fa-water-ladder', active: !!this.$store.state.biens.piscine },
          { libelle: 'Internet', icon: 'fa-solid fa-wifi', active: !!this.$store.state.biens.internet },
          { libelle: 'Balcon', icon: 'fa-solid fa-dungeon', active: !!this.$store.state.biens.balcon },
          { libelle: 'Jaccuzi', icon: 'fa-solid fa-hot-tub-person', active: !!this.$store.state.biens.jaccuzi },
          { libelle: 'Parking', icon: 'fa-solid fa-square-parking', active: !!this.$store.state.biens.parking },
          { libelle: 'Bord Mer', icon: 'fa-solid fa-water', active: !!this.$store.state.biens.bord_mer },
          { libelle: 'Bord Route', icon: 'fa-solid fa-road-bridge', active: !!this.$store.state.biens.bord_route },
  
          { libelle: 'climatisation', icon: 'fa-solid fa-fan', active: !!this.$store.state.biens.climatisation },
          { libelle: 'netflix', icon: 'fa-solid fa-n', active: !!this.$store.state.biens.netflix },
          { libelle: 'moustiquaire', icon: 'fa-solid fa-mosquito-net', active: !!this.$store.state.biens.moustiquaire },
          { libelle: 'refrigérateur', icon: 'fa-solid fa-box', active: !!this.$store.state.biens.refrigerateur },
          { libelle: 'lave machine', icon: 'fa-solid fa-soap', active: !!this.$store.state.biens.lave_machine },
        ],
        value1: [0, 180],
        selectedType: 'Types',
        filters: {
          piscine: false,
          internet: false,
          balcon: false,
          jaccuzi: false,
          parking: false,
          bordMer: false,
          bordRoute: false,
          climatisation: false,
          netflix: false,
          moustiquaire: false,
          refrigerateur: false,
          laveMachine: false,
          restaurant: false,
          evenement: false
        },
      };
    },
    methods: {
      toggleActive(item) {
        item.active = !item.active;
        this.updateFilters(item);
        this.ListeFiltre2();
      },
      updateFilters(item) {
        switch(item.libelle.toLowerCase()) {
          case 'piscine':
            this.filters.piscine = item.active ? 1 : 0;
            break;
          case 'internet':
            this.filters.internet = item.active ? 1 : 0;
            break;
          case 'balcon':
            this.filters.balcon = item.active ? 1 : 0;
            break;
          case 'jaccuzi':
            this.filters.jaccuzi = item.active ? 1 : 0;
            break;
          case 'parking':
            this.filters.parking = item.active ? 1 : 0;
            break;
          case 'bord mer':
            this.filters.bordMer = item.active ? 1 : 0;
            break;
          case 'bord route':
            this.filters.bordRoute = item.active ? 1 : 0;
            break;
          case 'climatisation':
            this.filters.climatisation = item.active ? 1 : 0;
            break;
          case 'netflix':
            this.filters.netflix = item.active ? 1 : 0;
            break;
          case 'moustiquaire':
            this.filters.moustiquaire = item.active ? 1 : 0;
            break;
          case 'refrigérateur':
            this.filters.refrigerateur = item.active ? 1 : 0;
            break;
          case 'lave machine':
            this.filters.laveMachine = item.active ? 1 : 0;
            break;
        }
  
        let data = {
              piscine: this.filters.piscine,
              internet: this.filters.internet,
              balcon: this.filters.balcon,
              jaccuzi: this.filters.jaccuzi,
              parking: this.filters.parking,
              bord_mer: this.filters.bordMer,
              bord_route: this.filters.bordRoute,
              climatisation: this.filters.climatisation,
              netflix: this.filters.netflix,
              moustiquaire: this.filters.moustiquaire,
              refrigerateur: this.filters.refrigerateur,
              lave_machine: this.filters.laveMachine
            }
  
        this.$store.commit('setFilter', data);
      },
      
      ListeFiltre2() {
        let dataa = {
              datas: null,
              total_row: null,
              total_pages: null,
            }
        this.$store.commit('setData', dataa);
        this.$store.commit('setBiensPage', 1);
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        const postData = {
          prix_min: this.value1[0] * 5000,
          prix_max: this.value1[1] * 5000,
          type: this.$store.state.biens.rtype,
          ville: this.$store.state.biens.rville,
          parpage: 10,
          commune: this.$store.state.biens.rquartier,
          piscine: this.$store.state.biens.piscine,
          internet: this.$store.state.biens.internet,
          balcon: this.$store.state.biens.balcon,
          jaccuzi: this.$store.state.biens.jaccuzi,
          parking: this.$store.state.biens.parking,
          bord_mer: this.$store.state.biens.bord_mer,
          bord_route: this.$store.state.biens.bord_route,
          climatisation: this.$store.state.biens.climatisation,
          netflix: this.$store.state.biens.netflix,
          moustiquaire: this.$store.state.biens.moustiquaire,
          refrigerateur: this.$store.state.biens.refrigerateur,
          lave_machine: this.$store.state.biens.lave_machine,
        };
  
        if (postData.prix === 'Tarif') {
          postData.prix = '0';
        }
  
        if (postData.type === 'Types') {
          postData.type = '0';
        }
  
        const randomId = Math.floor(Math.random() * 1000000);
        axios.post('/web_liste_appart.php?page='+ this.$store.state.biens.rpage +'&id='+ randomId, postData)
          .then(response => {
            let data = {
              datas: response.data.data,
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.$store.state.biens.rtype,
              rville: this.$store.state.biens.rville,
              rquartier: this.$store.state.biens.rquartier,
              rpage: this.$store.state.biens.rpage,
            }
            this.$store.commit('setDatas', data);
            let dons = response.data.data[0];
            this.$store.commit("setDonneesBien", dons);
          })
          .catch(error => {
            console.error('Erreur de requête:', error);
          })
          .finally(() => {
            loading.close();
          });
      },
    }
  };
  </script>
  
  <style scoped>
  .filter-container {
    width: 100%;
    background-color: #ffffff;
  }
  
  .navbar {
    max-width: 1625px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .content-wrapper {
    display: ruby;
    flex-direction: inherit;
    align-items: stretch;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .filter-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 70px;
    border: none;
    border-radius: 8px;
    background-color: #f3f4f65e;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .filter-button-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 70px;
    border: none;
    border-radius: 8px;
    background-color: #f3f4f65e;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .filter-button:hover {
    background-color: #e0e0e0;
  }
  
  .filter-button-mobile:hover {
    background-color: #e0e0e0;
  }
  
  .filter-button.active {
    background-color: #d191005c;
    color: #333333;
  }
  
  .filter-button-mobile.active {
    background-color: #d191005c;
    color: #333333;
  }
  
  .filter-icon {
    font-size: 24px;
    margin-bottom: 5px;
    color: #333333a1;
  }
  
  .filter-text {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
  }
  
  .filter-button.active .filter-icon,
  .filter-button.active .filter-text {
    color: #333333a1;
  }
  
  .info-text {
    margin-left: 280px;
    align-self: flex-end;
    font-size: 16px;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .button-container {
      justify-content: flex-start;
      overflow-x: auto;
      padding-bottom: 10px;
    }
  
    .filter-button {
      flex-shrink: 0;
    }
  
    .info-text {
      margin-top: 10px;
    }
  }
  </style>
  
  